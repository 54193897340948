// useGetTodaysTransactions.ts
import {useContext} from 'react';
import {UserContext} from '../../contexts/UserContext';
import {useQuery, gql} from '@apollo/client';
import {reportsClient} from '../apolloClients/reportsClient';

const GET_GRATUITIES = gql`
 query GratuityReport( $startDate: String!, $endDate: String!) {
  gratuityReport( startDate: $startDate, endDate: $endDate) {
    totalGratuity
    user
  }
}
`;

export const useGetGratuities = (startDate: string, endDate: string) => {
    const {token} = useContext(UserContext);
    const shouldSkip = !token;

    const {data, loading: apolloLoading, error, refetch} = useQuery(GET_GRATUITIES,
        {
            variables: {
                startDate: startDate,
                endDate: endDate
            },
            context: {
                headers: {
                    Authorization: "Bearer " + token,
                },
            },
            skip: shouldSkip,
            client: reportsClient,  // use the imported client
        });

    const isLoading = apolloLoading || shouldSkip;

    return [{
        data: data?.gratuityReport,
        isLoading,
        isError: !!error,
        refetch,
    }] as const;
};

