import {
    Button,
    ButtonGroup,
    useRadioGroup,
    Flex,
    Radio,
    RadioGroup,
    Spinner,
    HStack,
    Box,
    useRadio,
    Text
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { subDays, startOfWeek, endOfWeek, startOfDay, subWeeks, endOfDay, addDays } from 'date-fns';
import Card from 'components/card/Card';
import {useState, forwardRef} from "react";

import "react-datepicker/dist/react-datepicker.css";
import SalesByDayTable from "./components/SalesByDayTable";
import {getRolloverDates} from "../../../../helpers/getRolloverDates";

import CustomDateSelector from "../../../../components/reportDateSelector";
import {useGetSalesByDay} from "../../../../api/reports/getSalesByDay";

export default function SalesByDayReport() {
    const startThisWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
    const dateTimeToday = [getRolloverDates(1, startThisWeek)[0], new Date()];
    const [startDate, setStartDate] = useState(dateTimeToday[0]);
    const [endDate, setEndDate] = useState(dateTimeToday[1]);
    const [{ data: orders, isLoading, isError, refetch }] = useGetSalesByDay(null, startDate.toISOString(), endDate.toISOString())

    console.log("LEN",orders?.salesData)

    // @ts-ignore
    // @ts-ignore
    return (
        <Flex direction='column' pt={{ sm: '125px', lg: '75px' }}>
            <CustomDateSelector periods={"months"} handleDateChange={({startDate, endDate}: {startDate: Date, endDate: Date}) => {

                setStartDate(startDate);
                setEndDate(endDate);

                setTimeout(() => {
                    refetch({
                        query: {
                            closedDate_gt: startDate.toISOString(),
                            closedDate_lt: endDate.toISOString(),
                        },
                    });
                }, 0);
            }} />
            <Card px='0px'>
                {isLoading &&
                    <Spinner/>
                }
                {orders?.salesData?.length > 0 &&
                    <SalesByDayTable key={startDate.getTime()} tableData={orders} startDate={startDate} endDate={endDate} />
                }
            </Card>
        </Flex>
    );
}
