import {
    Flex,
    Spinner,
    Text
} from "@chakra-ui/react";
import { subDays, startOfWeek, endOfWeek, startOfDay, subWeeks, endOfDay, addDays } from 'date-fns';
import Card from 'components/card/Card';
import {useState, forwardRef, useEffect} from "react";

import "react-datepicker/dist/react-datepicker.css";
import SlowMoversTable from "./components/slowMoversTable";
import {getRolloverDates} from "../../../../helpers/getRolloverDates";

import CustomDateSelector from "../../../../components/reportDateSelector";
import {useGetSlowMovers} from "../../../../api/reports/getSlowMovers";


export default function SlowMoversReport() {
    const startThisWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
    const dateTimeToday = [getRolloverDates(1, startThisWeek)[0], new Date()];
    const [startDate, setStartDate] = useState(dateTimeToday[0]);
    const [endDate, setEndDate] = useState(dateTimeToday[1]);
    const [{
        data: orders,
        isLoading,
        isError,
        refetch
    }] = useGetSlowMovers(startDate.toISOString(), endDate.toISOString())


    // @ts-ignore
    // @ts-ignore
    return (
        <Flex direction='column' pt={{ sm: '125px', lg: '75px' }}>
            <CustomDateSelector periods={"months"} handleDateChange={({startDate, endDate}: {startDate: Date, endDate: Date}) => {
                console.log(startDate)
                console.log(endDate)
                setStartDate(startDate);
                setEndDate(endDate);

                setTimeout(() => {
                    refetch({
                        query: {
                            closedDate_gt: startDate.toISOString(),
                            closedDate_lt: endDate.toISOString(),
                        },
                    });
                }, 0);
            }} />
            <Card px='0px'>
                {isLoading &&
                    <Spinner/>
                }
                {orders?.length > 0 &&
                    <SlowMoversTable key={startDate.getTime()} tableData={orders} startDate={startDate} endDate={endDate}/>
                }
                {((!orders || orders.length === 0) && !isLoading) && (
                    <Flex
                        direction='column'
                        w='100%'
                        overflowX={{ sm: "scroll", lg: "hidden" }}>
                        <Text p={2}>No sales data available for the given date range.</Text>
                    </Flex>
                )}
            </Card>
        </Flex>
    );
}
