import {Box, Icon, List} from '@chakra-ui/react';
import {
  MdDashboard,
  MdHome,
  MdLock,
  MdOutlineShoppingCart, MdSettings, MdOutlineTableRestaurant, MdOutlinePayments
} from 'react-icons/md';

import {GiTable} from 'react-icons/gi';

// Admin Imports
import DashboardsDefault from 'views/admin/dashboards/default';

import NewProduct from 'views/admin/productLibrary/newProduct';
import ProductList from 'views/admin/productLibrary/productList';
import CategoryList from 'views/admin/productLibrary/categoryList';
import ProductPage from "./views/admin/productLibrary/product";
import LayoutList from "./views/admin/productLibrary/layoutList";
import LayoutPageGrid from "./views/admin/productLibrary/layout/grid2";
import ListPortions from "./views/admin/productLibrary/portionsList";
import Settings from "./views/admin/settings";
import Wizard from "./views/auth/Wizard";
import SignUpDefault from "./views/auth/SignUpDefault";
import Login from "./views/auth/Login";
import AppLogin from "./views/auth/AppLogin";
import ListTerminals from "./views/admin/terminals";
import {IoMdTabletLandscape} from "react-icons/io";
import ModifierGroup from "./views/admin/productLibrary/modifierGroup";
import ListModifierGroups from "./views/admin/productLibrary/modifierGroupList";
import OrdersReport from "./views/admin/reports/orders";
import SalesByUserReport from "./views/admin/reports/salesByUser";
import SalesByCategoryReport from "./views/admin/reports/salesByCategory";
import ListDiscounts from "./views/admin/productLibrary/discount";
import {HiOutlineDocumentReport} from "react-icons/hi";
import {FiUsers} from "react-icons/fi";
import ListUsers from "./views/admin/users";
import RefundsReport from "./views/admin/reports/refunds";
import TransactionReport from "./views/admin/reports/transactions";
import SalesReport from "./views/admin/reports/sales";
import SalesByDayReport from "./views/admin/reports/salesByDay";
import Tables from "./views/admin/tables";
import Printing from "./views/admin/printing";
import {TiPrinter} from "react-icons/ti";
import Billing from "./views/admin/billing";
import Chat from "./views/admin/chat";
import SalesByProductReport from "./views/admin/reports/salesByProduct";
import SlowMoversReport from "./views/admin/reports/slowMovers";
import GratuitiesReport from "./views/admin/reports/gratuities";
import ReportConfigurator from "./views/admin/reports/configurable/components/ReportConfigurator";
import Configurable from "./views/admin/reports/configurable";
import GroupingDragDropExample from "./views/admin/reports/configurable/configurable2";
import SalesPivotTableExample from "./views/admin/reports/configurable/conigurable3";
import FileUploadAndPreview from "./views/admin/productLibrary/productUpload";

const routes = [
  // --- Dashboards ---
  {
    name: 'Dashboard',
    path: '/dashboard',
    component: <DashboardsDefault />,
    layout: '/admin',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  },
  {
    name: 'Product Library',
    path: '/library',
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    collapse: true,
    items: [
      {
        name: 'Product Import',
        layout: '/admin',
        path: '/library/import',
        secondary: true,
        hidden: false,
        component: <FileUploadAndPreview />,
      },
      {
        name: 'New Product',
        layout: '/admin',
        path: '/library/new-product',
        secondary: true,
        hidden: true,
        component: <NewProduct />,
      },
      {
        name: 'Categories',
        layout: '/admin',
        path: '/library/categories',
        component: <CategoryList />,
        secondary: true,
      },
      {
        name: 'Products',
        layout: '/admin',
        path: '/library/products',
        component: <ProductList />,
        secondary: true,
      },
      {
        name: 'Product',
        layout: '/admin',
        path: '/library/products/:productId',
        component: <ProductPage />,
        secondary: true,
        hidden: true
      },
      {
        name: 'Layouts',
        layout: '/admin',
        path: '/library/layouts',
        component: <LayoutList />,
        secondary: true,
      },
      {
        name: 'Layout',
        layout: '/admin',
        path: '/library/layouts/:layoutId',
        component: <LayoutPageGrid />,
        secondary: true,
        hidden: true
      },

      {
        name: 'Portions',
        layout: '/admin',
        path: '/library/portions',
        component: <ListPortions />,
        secondary: true,
      },
      {
        name: 'Modifier Groups',
        layout: '/admin',
        path: '/library/modifiergrouplist',
        component: <ListModifierGroups />,
        secondary: true,
      },
      {
        name: 'Layout',
        layout: '/admin',
        path: '/library/modifiergrouplist/:modifierGroupId',
        component: <ModifierGroup />,
        secondary: true,
        hidden: true
      },
      {
        name: 'Discounts',
        layout: '/admin',
        path: '/library/discount',
        component: <ListDiscounts />,
        secondary: true,
      },


    ],

  },
  {
    name: 'Tables',
    path: '/tables',
    component: <Tables />,
    layout: '/admin',
    icon: <Icon as={MdOutlineTableRestaurant} width="20px" height="20px" color="inherit" />,
  },
  {
    name: 'Printing',
    path: '/printing',
    component: <Printing />,
    layout: '/admin',
    icon: <Icon as={TiPrinter} width="20px" height="20px" color="inherit" />,
  },
  {
    name: 'Reports',
    path: '/reports',
    icon: (
        <Icon
            as={HiOutlineDocumentReport}
            width="20px"
            height="20px"
            color="inherit"
        />
    ),
    collapse: true,
    items: [
      {
        name: 'Orders',
        layout: '/admin',
        path: '/reports/orders',
        secondary: true,
        component: <TransactionReport/>,
      },
      {
        name: 'Order Detail',
        layout: '/admin',
        path: '/reports/orderdetail',
        secondary: true,
        component: <SalesReport/>,
      },
      {
        name: 'Sales By Day',
        layout: '/admin',
        path: '/reports/salesbyday',
        secondary: true,
        component: <SalesByDayReport/>,
      },
      {
        name: 'Sales By User',
        layout: '/admin',
        path: '/reports/salesbyuser',
        secondary: true,
        component: <SalesByUserReport/>,
      },
      {
        name: 'Sales By Category',
        layout: '/admin',
        path: '/reports/salesbycategory',
        secondary: true,
        component: <SalesByCategoryReport/>,
      },
      {
        name: 'Sales By Product',
        layout: '/admin',
        path: '/reports/salesbyproduct',
        secondary: true,
        component: <SalesByProductReport/>,
      },
      {
        name: 'Refunds',
        layout: '/admin',
        path: '/reports/refunds',
        secondary: true,
        component: <RefundsReport/>,
      },
      {
        name: 'Slow Movers',
        layout: '/admin',
        path: '/reports/slowmovers',
        secondary: true,
        component: <SlowMoversReport/>,
      },
      {
        name: 'User Gratuities',
        layout: '/admin',
        path: '/reports/gratuities',
        secondary: true,
        component: <GratuitiesReport/>,
      },
      {
        name: 'Configurable',
        layout: '/admin',
        path: '/reports/configurable',
        secondary: true,
        component: <Configurable/>,
      },
      {
        name: 'Configurable',
        layout: '/admin',
        path: '/reports/configurable3',
        secondary: true,
        component: <SalesPivotTableExample/>,
      },
    ]
  },
  {
    name: 'Users',
    path: '/users',
    component: <ListUsers />,
    layout: '/admin',
    icon: <Icon as={FiUsers} width="20px" height="20px" color="inherit" />,
  },
  {
    name: 'System Settings',
    path: '/settings',
    component: <Settings />,
    layout: '/admin',
    icon: <Icon as={MdSettings} width="20px" height="20px" color="inherit" />,
  },

  {
    name: 'Terminals',
    path: '/terminals',
    component: <ListTerminals />,
    layout: '/admin',
    icon: <Icon as={IoMdTabletLandscape} width="20px" height="20px" color="inherit" />,
  },

  {
    name: 'Subscription',
    path: '/billing',
    component: <Billing />,
    layout: '/admin',
    icon: <Icon as={MdOutlinePayments} width="20px" height="20px" color="inherit" />,
  },

  ...(process.env.REACT_APP_ENABLE_AI === 'true'
      ? [
        {
          name: 'Pulse AI Chat',
          path: '/chat',
          component: <Chat />,
          layout: '/admin',
          icon: (
              <Box
                  position="relative"
                  display="inline-block"
                  width="20px"
                  height="20px"
                  color="inherit"
              >
                <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    borderRadius="50%"
                    width="9px" // White inner circle width
                    height="9px" // White inner circle height
                    backgroundColor="white" // White color for center
                    zIndex="1" // Ensures it’s above the pulsing effect
                />
                <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    borderRadius="50%"
                    width="15px"
                    height="15px"
                    backgroundColor="currentColor"
                    _after={{
                      content: '""',
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                      backgroundColor: "brand.500",
                      animation: "pulse 1.5s infinite",
                      opacity: 0.6,
                    }}
                />
              </Box>
          ),
        },
      ]
      : []),

  {
    name: 'Authentication',
    path: '/auth',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    collapse: true,
    hidden: true,
    items: [
      {
        name: 'Login',
        path: '/login',
        collapse: false,
        layout: '/auth',
        component: <Login />,
      },
      {
        name: 'AppLogin',
        path: '/applogin',
        collapse: false,
        layout: '/auth',
        component: <AppLogin />,
      },
      {
        name: 'Sign Up',
        layout: '/auth',
        path: '/signup',
        component: <SignUpDefault />,
      },
      {
        name: 'Wizard',
        path: '/wizard',
        collapse: false,
        layout: '/auth',
        component: <Wizard />,
      },
    ],
  },

];

export default routes;
