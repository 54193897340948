import React from "react";
import {
    VStack,
    Text,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    List,
    ListItem,
    Box, Flex, Button, Spacer,
} from "@chakra-ui/react";
import {IoCloudDownloadOutline} from "react-icons/io5";

type Block =
    | {
    type: "text";
    content: string;
}
    | {
    type: "table";
    headers: string[];
    rows: string[][];
}
    | {
    type: "list";
    items: string[];
};

type ChatMessageBlockProps = {
    block: Block;
};

const ChatMessageBlocks: React.FC<ChatMessageBlockProps> = ({ block }) => {

    const exportToCSV = (headers: string[], rows: string[][], filename: string) => {
        const csvContent =
            [headers.join(","), ...rows.map((row) => row.join(","))].join("\n");
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    if (block.type === "text") {
        return (
            <Text fontSize="md" color="gray.800" lineHeight="1.6">
                {block.content}
            </Text>
        );
    }

    if (block.type === "table") {
        return (
            <VStack align="stretch" spacing={4} p={2} bg="gray.50" borderRadius="md" boxShadow="sm" m={6}>
                <Flex justify="space-between" align="right">
                    <Spacer />
                    <Button rightIcon={<IoCloudDownloadOutline />} colorScheme='blue' variant='ghost' size={'sm'}>
                        csv
                    </Button>
                </Flex>
            <Box>
                <Table variant="simple" size={"sm"} mb={7}>
                    <Thead>
                        <Tr>
                            {block.headers.map((header, headerIndex) => (
                                <Th key={headerIndex}>{header}</Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {block.rows.map((row, rowIndex) => (
                            <Tr key={rowIndex}>
                                {row.map((cell, cellIndex) => (
                                    <Td key={cellIndex}>{cell}</Td>
                                ))}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>
            </VStack>
        );
    }

    if (block.type === "list") {
        return (
            <List spacing={2} pl={4} styleType="disc">
                {block.items.map((item, idx) => (
                    <ListItem key={idx} fontSize="md" color="gray.800">
                        {item}
                    </ListItem>
                ))}
            </List>
        );
    }

    return null; // Fallback for unknown block types
};

export default ChatMessageBlocks;
