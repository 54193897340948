// components/ReportTable.jsx
//@ts-nocheck

import React, { useMemo } from "react";
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Box,
    Text
} from "@chakra-ui/react";

export default function ReportTable({ orders, config }) {
    const { selectedColumns, groupBy } = config;

    // Move your useMemo above any conditional returns
    const groupedData = useMemo(() => {
        if (!groupBy) {
            // If there's no grouping, just return `null` or the raw data
            return null;
        }
        return orders.reduce((acc, item) => {
            const key = item[groupBy] || "Unknown";
            if (!acc[key]) acc[key] = [];
            acc[key].push(item);
            return acc;
        }, {});
    }, [orders, groupBy]);

    // Now do your conditional return AFTER the hook is called
    if (!groupBy) {
        // If there's no group, just show all orders in a single table
        return (
            <SimpleDataTable
                data={orders}
                columns={selectedColumns}
            />
        );
    }

    // If grouping is enabled, render the grouped tables
    return (
        <>
            {Object.keys(groupedData).map((groupKey) => (
                <div key={groupKey}>
                    <h3>{groupBy}: {groupKey}</h3>
                    <SimpleDataTable
                        data={groupedData[groupKey]}
                        columns={selectedColumns}
                    />
                </div>
            ))}
        </>
    );
}

function SimpleDataTable({ data, columns }) {
    return (
        <Table size="sm">
            <Thead>
                <Tr>
                    {columns.map((col) => (
                        <Th key={col}>{col}</Th>
                    ))}
                </Tr>
            </Thead>
            <Tbody>
                {data.map((row) => (
                    <Tr key={row.id}>
                        {columns.map((col) => (
                            <Td key={col}>{row[col]}</Td>
                        ))}
                    </Tr>
                ))}
            </Tbody>
        </Table>
    );
}
