import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Text } from '@chakra-ui/react';

const MyDropzone = ({ onFileAccepted }: { onFileAccepted: (file: File) => void }) => {
    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
            onFileAccepted(acceptedFiles[0]);
        }
    }, [onFileAccepted]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: '.csv',
        multiple: false,
    });

    return (
        <Box
            {...getRootProps()}
            border="2px dashed"
            p={10}
            textAlign="center"
            borderRadius="md"
            cursor="pointer"
        >
            <input {...getInputProps()} />
            {isDragActive ? (
                <Text>Drop the CSV file here...</Text>
            ) : (
                <Text>Drag 'n' drop a CSV file here, or click to select one</Text>
            )}
        </Box>
    );
};

export default MyDropzone;
