import {
    Button,
    ButtonGroup,
    useRadioGroup,
    Flex,
    Radio,
    RadioGroup,
    Spinner,
    HStack,
    Box,
    useRadio,
    Text
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { subDays, startOfWeek, endOfWeek, startOfDay, subWeeks, endOfDay, addDays } from 'date-fns';
import Card from 'components/card/Card';
import {useState, forwardRef} from "react";

import "react-datepicker/dist/react-datepicker.css";
import SalesReportTable from "./components/SalesReportTable";
import {useGetSalesData} from "../../../../api/reports/getSalesData";
import {getRolloverDates} from "../../../../helpers/getRolloverDates";

import CustomDateSelector from "../../../../components/reportDateSelector";

export default function SalesReport() {
    const dateTimeToday = getRolloverDates(1, startOfDay(new Date()));
    const [startDate, setStartDate] = useState(dateTimeToday[0]);
    const [endDate, setEndDate] = useState(dateTimeToday[1]);
    const [{ data: orders, isLoading, isError, refetch }] = useGetSalesData(startDate.toISOString(), endDate.toISOString())

console.log("ORDERS", orders)

    // @ts-ignore
    // @ts-ignore
    return (
        <Flex direction='column' pt={{ sm: '125px', lg: '75px' }}>
            <CustomDateSelector handleDateChange={({startDate, endDate}: {startDate: Date, endDate: Date}) => {
                console.log(startDate)
                console.log(endDate)
                setStartDate(startDate);
                setEndDate(endDate);

                setTimeout(() => {
                    refetch({
                        query: {
                            closedDate_gt: startDate.toISOString(),
                            closedDate_lt: endDate.toISOString(),
                        },
                    });
                }, 0);
            }} />
            <Card px='0px'>
                {isLoading &&
                    <Spinner/>
                }
                {orders?.length > 0 &&
                    <SalesReportTable key={startDate.getTime()} tableData={orders} startDate={startDate} endDate={endDate} />
                }
                {((!orders || orders.length === 0) && !isLoading) && (
                    <Flex
                        direction='column'
                        w='100%'
                        overflowX={{ sm: "scroll", lg: "hidden" }}>
                        <Text p={2}>No sales data available for the given date range.</Text>
                    </Flex>
                )}
            </Card>
        </Flex>
    );
}
