import React, { useState } from 'react';
import {
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Container,
    Button,
    Flex,
    Input,
    Spinner,
    Text,
    VStack,
    HStack,
    Select,
    Code,
    Switch, Box,
} from '@chakra-ui/react';
import axios from 'axios';
import MyDropzone from "./dropzone";
import Card from 'components/card/Card';
import CustomColorPicker from "../productList/components/CustomCirclePicker";
import useGetTaxRates from "../../../../api/productLibrary/getTaxRates";
import CategoryIconPicker from "./categoryIconPicker";
import {getDefaultIconForCategory} from "./categoryDefaultIcon";

interface Category {
    id: string;
    name: string;
    icon?: string;
}

interface Portion {
    name: string;
    price: number;
}

interface Product {
    name: string;
    category: string;
    price?: number;
    portions?: Portion[];
    buttonColor?: string; // For the product's button colour
    taxRate?: string;     // The tax rate _id to be applied if applicable
    disabled?: boolean;   // When true, the product is disabled
    tax_rate?: string;    // The raw tax_rate from CSV (optional)
}

interface ParsedData {
    categories: Category[];
    products: Product[];
}

const colorPalette = [
    "#0165FF",
    "#00A698",
    "#19A43B",
    "#DC1932",
    "#892FC6",
    "#DB7500",
    "#DC188C",
    "#2F2F47"
];


const MultiStepFormTabs: React.FC = () => {
    const [step, setStep] = useState<number>(0);
    const [parsedData, setParsedData] = useState<ParsedData | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const [{ data: taxrates }] = useGetTaxRates();

    // Called when a file is dropped
    const handleFileUpload = async (file: File) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', 'tabology');

        try {
            const response = await axios.post('/api/productUpload', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            let data = response.data.data;

            // For each product, assign a button color round-robin,
            // default "disabled" flag to false,
            // and if the CSV's tax_rate is not 0, add a taxRate using the taxrates hook.
            data.products = data.products.map((prod: Product, idx: number) => {
                const updatedProd: Product = {
                    ...prod,
                    buttonColor: colorPalette[idx % colorPalette.length],
                    disabled: false, // default to active
                };
                if (prod.tax_rate && parseFloat(prod.tax_rate) !== 0 && taxrates && taxrates.length > 0) {
                    updatedProd.taxRate = taxrates[0]._id;
                }
                return updatedProd;
            });

            setParsedData(data);
            setStep(1);
        } catch (error) {
            console.error('Upload error:', error);
        }
        setLoading(false);
    };

    // Final "Save" just logs the modified JSON for now
    const handleSave = () => {
        console.log('Modified JSON:', parsedData);
        alert('Modified JSON logged in console.');
    };

    return (
        <Container maxW="container.md" py={8} pt={100}>
            <Tabs index={step} onChange={(index) => setStep(index)} variant="enclosed">
                <TabList mb="1em">
                    <Tab>Upload CSV</Tab>
                    <Tab>Edit Categories</Tab>
                    <Tab>Edit Products</Tab>
                    <Tab>Review & Save</Tab>
                </TabList>
                <TabPanels>
                    {/* Step 1: Upload CSV */}
                    <TabPanel>
                        <Text fontSize="xl" mb={4}>Step 1: Upload CSV</Text>
                        <MyDropzone onFileAccepted={handleFileUpload} />
                        {loading && (
                            <Flex mt={4} justify="center">
                                <Spinner />
                            </Flex>
                        )}
                        <Button mt={4} onClick={() => setStep(1)} disabled={!parsedData}>
                            Next
                        </Button>
                    </TabPanel>

                    {/* Step 2: Edit Categories */}
                    <TabPanel>
                        <Text fontSize="xl" mb={4}>Step 2: Edit Categories</Text>
                        {parsedData && (
                            <VStack spacing={4} align="stretch">
                                {parsedData.categories.map((cat) => (
                                    <Box key={cat.id} p={4} borderWidth="1px" borderRadius="md">
                                        <Input
                                            defaultValue={cat.name}
                                            placeholder="Category Name"
                                            onBlur={(e) => {
                                                const newName = e.target.value;
                                                setParsedData((prev) => {
                                                    if (!prev) return prev;
                                                    const updatedCategories = prev.categories.map((c) =>
                                                        c.id === cat.id ? { ...c, name: newName } : c
                                                    );
                                                    return { ...prev, categories: updatedCategories };
                                                });
                                            }}
                                        />
                                        <Text mt={2}>Select Icon:</Text>
                                        <CategoryIconPicker
                                            currentIcon={cat.icon || getDefaultIconForCategory(cat.name)}
                                            onSelect={(newIconName: string) => {
                                                setParsedData((prev) => {
                                                    if (!prev) return prev;
                                                    const updatedCategories = prev.categories.map((c) =>
                                                        c.id === cat.id ? { ...c, icon: newIconName } : c
                                                    );
                                                    return { ...prev, categories: updatedCategories };
                                                });
                                            }}
                                        />
                                    </Box>
                                ))}
                            </VStack>
                        )}
                        <HStack mt={4}>
                            <Button onClick={() => setStep(0)}>Back</Button>
                            <Button
                                onClick={() => {
                                    // Update each category to ensure an icon is set before moving on.
                                    setParsedData((prev) => {
                                        if (!prev) return prev;
                                        const updatedCategories = prev.categories.map((cat) => ({
                                            ...cat,
                                            icon: cat.icon || getDefaultIconForCategory(cat.name),
                                        }));
                                        return { ...prev, categories: updatedCategories };
                                    });
                                    setStep(2);
                                }}
                                disabled={!parsedData}
                            >
                                Next
                            </Button>
                        </HStack>
                    </TabPanel>

                    {/* Step 3: Edit Products */}
                    <TabPanel>
                        <Text fontSize="xl" mb={4}>Step 3: Edit Products</Text>
                        {parsedData && (
                            <VStack spacing={6} align="stretch">
                                {parsedData.products.map((prod, idx) => (
                                    <Card key={idx} p={4} borderWidth="1px" borderRadius="md">
                                        <Text mb={2}>Product Name:</Text>
                                        <Input
                                            defaultValue={prod.name}
                                            onBlur={(e) => {
                                                const newName = e.target.value;
                                                setParsedData((prev) => {
                                                    if (!prev) return prev;
                                                    const updatedProducts = prev.products.map((p, i) =>
                                                        i === idx ? { ...p, name: newName } : p
                                                    );
                                                    return { ...prev, products: updatedProducts };
                                                });
                                            }}
                                        />
                                        <Text mt={2}>Assign Category:</Text>
                                        <Select
                                            value={prod.category}
                                            onChange={(e) => {
                                                const newCategory = e.target.value;
                                                setParsedData((prev) => {
                                                    if (!prev) return prev;
                                                    const updatedProducts = prev.products.map((p, i) =>
                                                        i === idx ? { ...p, category: newCategory } : p
                                                    );
                                                    return { ...prev, products: updatedProducts };
                                                });
                                            }}
                                        >
                                            {parsedData.categories.map((cat) => (
                                                <option key={cat.id} value={cat.id}>
                                                    {cat.name}
                                                </option>
                                            ))}
                                        </Select>
                                        {prod.portions && prod.portions.length > 0 ? (
                                            <>
                                                <Text mt={2}>Portions:</Text>
                                                {prod.portions.map((portion, pIdx) => (
                                                    <HStack key={pIdx} spacing={4} mt={2}>
                                                        <Input
                                                            defaultValue={portion.name}
                                                            placeholder="Portion Name"
                                                            onBlur={(e) => {
                                                                const newPortionName = e.target.value;
                                                                setParsedData((prev) => {
                                                                    if (!prev) return prev;
                                                                    const updatedProducts = prev.products.map((p, i) => {
                                                                        if (i === idx && p.portions) {
                                                                            const updatedPortions = p.portions.map((pt, j) =>
                                                                                j === pIdx ? { ...pt, name: newPortionName } : pt
                                                                            );
                                                                            return { ...p, portions: updatedPortions };
                                                                        }
                                                                        return p;
                                                                    });
                                                                    return { ...prev, products: updatedProducts };
                                                                });
                                                            }}
                                                        />
                                                        <Input
                                                            defaultValue={(portion.price / 100).toFixed(2)}
                                                            placeholder="Price"
                                                            onBlur={(e) => {
                                                                const newPrice = parseFloat(e.target.value) * 100;
                                                                setParsedData((prev) => {
                                                                    if (!prev) return prev;
                                                                    const updatedProducts = prev.products.map((p, i) => {
                                                                        if (i === idx && p.portions) {
                                                                            const updatedPortions = p.portions.map((pt, j) =>
                                                                                j === pIdx ? { ...pt, price: newPrice } : pt
                                                                            );
                                                                            return { ...p, portions: updatedPortions };
                                                                        }
                                                                        return p;
                                                                    });
                                                                    return { ...prev, products: updatedProducts };
                                                                });
                                                            }}
                                                        />
                                                    </HStack>
                                                ))}
                                            </>
                                        ) : prod.price !== undefined ? (
                                            <>
                                                <Text mt={2}>Price:</Text>
                                                <Input
                                                    defaultValue={(prod.price / 100).toFixed(2)}
                                                    onBlur={(e) => {
                                                        const newPrice = parseFloat(e.target.value) * 100;
                                                        setParsedData((prev) => {
                                                            if (!prev) return prev;
                                                            const updatedProducts = prev.products.map((p, i) =>
                                                                i === idx ? { ...p, price: newPrice } : p
                                                            );
                                                            return { ...prev, products: updatedProducts };
                                                        });
                                                    }}
                                                />
                                            </>
                                        ) : null}
                                        <Text mt={2}>Select Button Color:</Text>
                                        <CustomColorPicker
                                            defaultColor={prod.buttonColor || ''}
                                            onChangeComplete={(color: string) => {
                                                setParsedData((prev) => {
                                                    if (!prev) return prev;
                                                    const updatedProducts = prev.products.map((p, i) =>
                                                        i === idx ? { ...p, buttonColor: color } : p
                                                    );
                                                    return { ...prev, products: updatedProducts };
                                                });
                                            }}
                                        />
                                        <Flex align="center" mt={4}>
                                            <Switch
                                                isChecked={!prod.disabled}
                                                onChange={(e) => {
                                                    const isEnabled = e.target.checked;
                                                    setParsedData((prev) => {
                                                        if (!prev) return prev;
                                                        const updatedProducts = prev.products.map((p, i) =>
                                                            i === idx ? { ...p, disabled: !isEnabled } : p
                                                        );
                                                        return { ...prev, products: updatedProducts };
                                                    });
                                                }}
                                                size="sm"
                                            />
                                            <Text ml={2}>{!prod.disabled ? "Charge Tax" : "No Tax"}</Text>
                                        </Flex>

                                    </Card>
                                ))}
                            </VStack>
                        )}
                        <HStack mt={4}>
                            <Button onClick={() => setStep(1)}>Back</Button>
                            <Button onClick={() => setStep(3)} disabled={!parsedData} colorScheme={"brand"}>
                                Next &gt;
                            </Button>
                        </HStack>
                    </TabPanel>

                    {/* Step 4: Review & Save */}
                    <TabPanel>
                        <Text fontSize="xl" mb={4}>Step 4: Review Modified Data</Text>
                        <Code p={4} display="block" whiteSpace="pre-wrap">
                            {JSON.stringify(parsedData, null, 2)}
                        </Code>
                        <HStack mt={4}>
                            <Button onClick={() => setStep(2)}>Back</Button>
                            <Button onClick={handleSave}>Save</Button>
                        </HStack>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Container>
    );
};

export default MultiStepFormTabs;
