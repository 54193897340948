import { useState } from "react";
import { Box, IconButton, Text } from "@chakra-ui/react";
import { AiOutlineClose, AiOutlineMessage } from "react-icons/ai";
import PopupChat from "./popupChat";

export default function ChatPopup() {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Box>
            {/* Floating Action Button */}
            {!isOpen && (
                <IconButton
                    icon={<AiOutlineMessage />}
                    onClick={() => setIsOpen(true)}
                    position="fixed"
                    bottom="20px"
                    right="20px"
                    colorScheme="blue"
                    aria-label="Open Chat"
                    borderRadius="full"
                    boxShadow="lg"
                />
            )}

            {/* Popup Container */}
            {isOpen && (
                <Box
                    position="fixed"
                    bottom="20px"
                    right="20px"
                    width="475px"
                    height="500px"
                    bg="white"
                    borderRadius="lg"
                    boxShadow="xl"
                    display="flex"
                    flexDirection="column"
                    overflow="hidden"
                    zIndex="10"
                >
                    {/* Header */}
                    <Box
                        bg="blue.500"
                        color="white"
                        p={4}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Text fontSize="md" fontWeight="bold">
                            Pulse Chat
                        </Text>
                        <IconButton
                            icon={<AiOutlineClose />}
                            aria-label="Close"
                            size="sm"
                            colorScheme="white"
                            variant="ghost"
                            onClick={() => setIsOpen(false)}
                        />
                    </Box>

                    {/* Main Content */}
                    <Box flex="1" display="flex" overflow="hidden">
                        <PopupChat />
                    </Box>
                </Box>
            )}
        </Box>
    );
}
