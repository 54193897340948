import { useContext, useState, useEffect, useRef } from "react";
import { useChat } from "ai/react";
import { Box, VStack, Input, Button, Spinner, Text } from "@chakra-ui/react";
import { UserContext } from "../../../contexts/UserContext";
import ChatMessageBlocks from "./components/chatMessageBlocks";
import {
    Alert,
    AlertIcon,
} from '@chakra-ui/react'
import {generateId} from "ai";

type Block =
    | {
    type: "text";
    content: string;
}
    | {
    type: "table";
    headers: string[];
    rows: string[][];
}
    | {
    type: "list";
    items: string[];
};

type ParsedMessage = {
    role: "user" | "assistant";
    preamble: string;
    structuredData?: Block[];
};

export default function Chat() {
    const { token } = useContext(UserContext);

    const { messages, input, setInput, handleSubmit, isLoading } = useChat({
        api: "/api/chat",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        initialMessages:[
            {
                id: generateId(6),
                role: "assistant",
                content: "Hello! I'm Seamless Pulse. Ask me questions like, what were my best selling products last month? Who sold the most last week? Or, what was my revenue last quarter?",
            }
        ]
    });

    const [parsedMessages, setParsedMessages] = useState<ParsedMessage[]>([]);
    const currentMessage = useRef<ParsedMessage | null>(null); // Track the currently streaming message
    const partialJSON = useRef(""); // Buffer for incomplete JSON
    const isInsideJSON = useRef(false); // Flag to detect structured data block

    useEffect(() => {
        const updatedMessages: ParsedMessage[] = [];

        messages.forEach((msg) => {
            if (msg.role === "assistant") {
                // If this is a new assistant message, reset the current message
                if (!currentMessage.current || currentMessage.current.preamble !== msg.content) {
                    currentMessage.current = {
                        role: "assistant",
                        preamble: "", // Start with an empty preamble
                        structuredData: [], // Initialize structured data as empty
                    };
                }

                // Split content by lines and parse progressively
                const chunks = msg.content.split("\n");
                chunks.forEach((chunk) => {
                    if (chunk.startsWith("```json")) {
                        isInsideJSON.current = true;
                        partialJSON.current = ""; // Start buffering JSON
                    } else if (chunk.startsWith("```") && isInsideJSON.current) {
                        // Close JSON block and parse it
                        isInsideJSON.current = false;
                        try {
                            const parsed = JSON.parse(partialJSON.current);
                            currentMessage.current.structuredData = parsed; // Add parsed data
                        } catch (error) {
                            console.error("Error parsing JSON:", error);
                        }
                        partialJSON.current = ""; // Clear JSON buffer
                    } else if (isInsideJSON.current) {
                        partialJSON.current += chunk; // Continue buffering JSON
                    } else {
                        currentMessage.current.preamble += chunk + "\n"; // Append to preamble
                    }
                });

                // Push the current message to updated messages
                if (currentMessage.current) {
                    updatedMessages.push({ ...currentMessage.current });
                }
            } else {
                // For user messages, just add them directly
                updatedMessages.push({ role: "user", preamble: msg.content });
            }
        });

        setParsedMessages(updatedMessages);
    }, [messages]);

    return (
    <Box>
        <Box w="full" display="flex" flexDirection="column" px={4} bg="gray.50" pt={75}>
        <Alert status='warning'>
            <AlertIcon />
            Pulse AI chat is a beta feature. Please use it with caution and do not rely on it for business decisions without double checking the information.
        </Alert>
        </Box>

        <Box w="full" h="79vh" display="flex" flexDirection="column" p={4} bg="gray.50">

            {/* Scrolling container for chat messages */}
            <Box
                flex="1"
                overflowY="auto"
                bg="white"
                borderRadius="md"
                boxShadow="sm"
                p={4}
            >
                <VStack spacing={4} align="stretch">
                    {parsedMessages.map((msg, idx) => (
                        <Box
                            key={idx}
                            alignSelf={msg.role === "user" ? "flex-end" : "flex-start"}
                            bg={msg.role === "user" ? "blue.500" : "gray.200"}
                            color={msg.role === "user" ? "white" : "black"}
                            p={3}
                            borderRadius="lg"
                        >
                            {/* Render preamble immediately */}
                            {msg.preamble && <Text>{msg.preamble.trim()}</Text>}

                            {/* Render structured data only after JSON parsing completes */}
                            {msg.structuredData &&
                                msg.structuredData.map((block, blockIdx) => (
                                    <ChatMessageBlocks key={blockIdx} block={block} />
                                ))}
                        </Box>
                    ))}

                    {isLoading && (
                        <Box alignSelf="flex-start">
                            <Spinner size="sm" color="blue.500" />
                            <Box mt={2} color="gray.600" fontSize="sm">
                                Assistant is typing...
                            </Box>
                        </Box>
                    )}
                </VStack>
            </Box>

            {/* Input box at bottom */}
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}
            >
                <Box mt={4} display="flex" gap={2}>
                    <Input
                        placeholder="Type your question..."
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        disabled={isLoading}
                    />
                    <Button type="submit" colorScheme="blue" isLoading={isLoading}>
                        Send
                    </Button>
                </Box>
            </form>
        </Box>
    </Box>
    );
}
