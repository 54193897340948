// Chakra imports
import {Box, Button, useToast, Text, Flex} from '@chakra-ui/react';
import Card from 'components/card/Card';

import ProductTable from "./components/ProductTable";
import useGetProducts from "../../../../api/productLibrary/getProducts";
import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../../../contexts/UserContext";
import PaddedFlex from "../../../../components/PaddedFlex";
import SeamlessSpinner from "../../../../components/SeamlessSpinner";
import {useNavigate} from "react-router-dom";
import AddProduct from "./components/AddProduct";
import AddNewCard from "../../../../components/AddNewCard";
import useGetOrderModes from "../../../../api/productLibrary/getOrderModes";
import useGetTaxRates from "../../../../api/productLibrary/getTaxRates";

export default function ListProducts() {
    const [{data, isLoading, isError}] = useGetProducts();

    const [{data: orderModeData}] = useGetOrderModes();

    const [{data: taxRateData}] = useGetTaxRates();
    const {isAppSession} = useContext(UserContext);
    const toast = useToast();
    const navigate = useNavigate();

    useEffect(() => {
            if (isError) {
                toast({
                    title: "Error loading products.",
                    description: "There was an error while loading the products. Please try again later.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        },
        [isError, toast]);

    let padding = {sm: '125px', lg: '75px'};

    if (isAppSession) {
        padding = {sm: '60px', lg: '60px'};
    }

    return (
        <PaddedFlex direction='column' pt={padding}>

            <Card px='0px'>
                {isLoading &&
                    <SeamlessSpinner/>
                }

                {!isLoading && (!data || data.length === 0) &&
                    <Flex justify='center' alignItems='center'>
                        <Flex maxW={'50%'}>
                            <AddNewCard name={"Product"} pluralName={"Products"}
                                        action={() => navigate('/admin/library/new-product')}/>
                        </Flex>
                    </Flex>
                }

                {data?.length > 0 && orderModeData?.length > 0 &&
                    <ProductTable tableData={data} orderModes={orderModeData} taxRates={taxRateData}/>
                }
            </Card>
        </PaddedFlex>
    );
}
