import { icons} from "./categoryIconPicker";

export const getDefaultIconForCategory = (categoryName: string): string => {
    const lowerCategory = categoryName.toLowerCase();
    for (const iconObj of icons) {
        if (iconObj.tags.some(tag => lowerCategory.includes(tag))) {
            return iconObj.name;
        }
    }
    return icons[0].name; // fallback default icon name
};

