// Chakra imports
import {
    Portal,
    Box,
    useDisclosure,
    useColorModeValue,
    useToast,
    Center,
    Spinner, VStack, Text
} from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin';
// Layout components
import Navbar from 'components/navbar/NavbarAdmin';
import Sidebar from 'components/sidebar/Sidebar';
import {SidebarContext} from 'contexts/SidebarContext';
import {useContext, useEffect, useState} from 'react';
import {Route, Routes, Navigate, useLocation, useNavigate} from 'react-router-dom';
import routes from 'routes';
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
//import {AblyProvider, usePresence} from "ably/react";
import Ably from "ably/callbacks";
import {UserContext} from "../../contexts/UserContext";
import {DeviceProvider} from "../../contexts/DeviceContext";
import * as Sentry from "@sentry/react"

// Custom Chakra theme
export default function Dashboard(props: { [x: string]: any }) {
    const {...rest} = props;
    // states and functions
    const [fixed] = useState(false);
    const [toggleSidebar, setToggleSidebar] = useState(false);
    const [mini, setMini] = useState(false);
    const [hovered, setHovered] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [presentDevices, setPresentDevices] = useState([]);

    const [activeParent, setActiveParent] = useState<string|null>(null);
    const location = useLocation();

    const {getFlag} = useKindeAuth();
    const navigate = useNavigate();

    const {token, user, isAuthenticated, isKindeLoading, isAppSession} = useContext(UserContext);

    // const { presenceData, updateStatus } = usePresence('tableOrders', 'initialPresenceStatus');

    useEffect(() => {
        // Splitting the current pathname
        const pathParts = location.pathname.split("/").filter(Boolean);
        if(pathParts.length > 2) {
            // Get parent route part from the pathname
            const parentRoutePart = pathParts[1];
            // Find the parent route object in the routes array
            const parentRoute = routes.find(route => route.path.split("/").includes(parentRoutePart));
            // Set the active parent
            if(parentRoute) {
                setActiveParent(parentRoute.name);
            }
        } else {
            setActiveParent(null);
        }
    }, [location]);

    /*
    useEffect(() => {
      const newPresentDevices = presenceData.filter(item => item.clientId !== 'backoffice');

      const addedDevices = newPresentDevices.filter(({clientId:id1}) => !presentDevices.some(({clientId:id2}) => id2 === id1));
      const removedDevices = presentDevices.filter(({clientId:id1}) => !newPresentDevices.some(({clientId:id2}) => id2 === id1));

      addedDevices.forEach(device => {
        toast({
          title: `Terminal 1 has come online`,
          position: 'top-right',
          isClosable: true,
        })
      })

      removedDevices.forEach(device => {
        toast({
          title: `Terminal 1 has gone offline`,
          position: 'top-right',
          isClosable: true,
          status: 'warning'
        })
      })

      setPresentDevices(newPresentDevices);

    }, [presenceData]); */

    useEffect(() => {

        if (getFlag) {

            try {
                let wizardVar = sessionStorage.getItem("wizardComplete");

                if (getFlag('account-setup-complete').value === false && !wizardVar) {
                    navigate('/auth/wizard');
                }

            } catch (e) {
               // console.log("Error: ", e);
            }
        }

    }, [getFlag]);

    const [title, setTitle] = useState(null);
    const [showBack, setShowBack] = useState(false);

    // Reset the `title` and `showBack` states whenever the location changes
    useEffect(() => {
        setTitle(null); // Clear the title
        setShowBack(false); // Reset the back button
    }, [location]);

    // functions for changing the states from components
    const getRoute = () => {
        return window.location.pathname !== '/admin/full-screen-maps';
    };
    const getActiveRoute = (routes: RoutesType[]): string => {
        /* console.log("CALLED"); */
        let activeRoute = title;
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].items);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else {
                if (
                    window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
                ) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    };
    const getActiveNavbar = (routes: RoutesType[]): boolean => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveNavbar = getActiveNavbar(routes[i].items);
                if (collapseActiveNavbar !== activeNavbar) {
                    return collapseActiveNavbar;
                }
            } else {
                if (
                    window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
                ) {
                    return routes[i].secondary;
                }
            }
        }
        return activeNavbar;
    };

    const getRoutes = (routes: RoutesType[]): any => {
        return routes.map((route: RoutesType, key: number) => {
            if (route.layout === '/admin') {
                return (
                    <Route path={`${route.path}`} element={AddExtraProps(route.component, {setTitle, setShowBack})} key={key}/>
                );
            }
            if (route.collapse) {
                return getRoutes(route.items);
            } else {
                return null;
            }
        });
    };

    // @ts-ignore
    function AddExtraProps(Component, extraProps) {
        return <Component.type {...Component.props} {...extraProps} />;
    }

    const isMobileSafari = /iP(ad|od|hone)/i.test(navigator.userAgent) && /WebKit/i.test(navigator.userAgent) && !(/(CriOS|FxiOS)/i.test(navigator.userAgent));

  //  let ablyClient;
   // if(token) {
     /*   console.log("TOKEN SO CONNECTING");
       let ablyClient = new Ably.Realtime.Promise({
            authUrl: 'https://qavdxdjgdyvisurs73tzrsqhva0edqxw.lambda-url.eu-west-1.on.aws/',
            authHeaders: {"Authorization": token},
            clientId: 'backoffice',
            autoConnect: !isMobileSafari
        });
   // } */


    document.documentElement.dir = 'ltr';
    const {onOpen} = useDisclosure();
    const bg = useColorModeValue('background.100', 'background.900');
    const {pathname} = useLocation();


    if(!isKindeLoading && !isAuthenticated && !isAppSession) {
        return <Navigate to="/auth/login" replace/>
    }

    if (!user) {
        return( <Box><Center h="100vh">
            <VStack spacing={2}>
                <Spinner size="xl" />
                <Text>Loading...</Text>
            </VStack>
        </Center></Box> )
    }

   /* if (!token) {
        return( <Box><Center h="100vh">
            <VStack spacing={2}>
                <Spinner size="xl" />
                <Text>Loading....</Text>
            </VStack>
        </Center></Box> )
    } */

    return (
            <DeviceProvider>
                <Box bg={bg} h="100vh" w="100vw">
                    <SidebarContext.Provider
                        value={{
                            toggleSidebar,
                            setToggleSidebar,
                        }}
                    >
                        <Sidebar
                            hovered={hovered}
                            setHovered={setHovered}
                            mini={mini}
                            routes={routes}
                            display="none"
                            {...rest}
                        />
                        <Box
                            float="right"
                            minHeight="100vh"
                            height="100%"
                            overflow="auto"
                            position="relative"
                            maxHeight="100%"
                            w={
                                mini === false
                                    ? {base: '100%', xl: 'calc( 100% - 290px )'}
                                    : mini === true && hovered === true
                                        ? {base: '100%', xl: 'calc( 100% - 290px )'}
                                        : {base: '100%', xl: 'calc( 100% - 120px )'}
                            }
                            maxWidth={
                                mini === false
                                    ? {base: '100%', xl: 'calc( 100% - 290px )'}
                                    : mini === true && hovered === true
                                        ? {base: '100%', xl: 'calc( 100% - 290px )'}
                                        : {base: '100%', xl: 'calc( 100% - 120px )'}
                            }
                            transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
                            transitionDuration=".2s, .2s, .35s"
                            transitionProperty="top, bottom, width"
                            transitionTimingFunction="linear, linear, ease"
                        >
                            <Portal>
                                <Box>
                                    <Navbar
                                        hovered={hovered}
                                        setMini={setMini}
                                        mini={mini}
                                        onOpen={onOpen}
                                        logoText={'Horizon UI Dashboard PRO'}
                                        brandText={title && title.trim() !== '' ? title : getActiveRoute(routes)}
                                        subBrandText={activeParent}
                                        secondary={getActiveNavbar(routes)}
                                        theme={props.theme}
                                        setTheme={props.setTheme}
                                        fixed={fixed}
                                        showBack={showBack}
                                        {...rest}
                                        pathname={pathname}
                                    />
                                </Box>
                            </Portal>

                            {getRoute() ? (
                                <Box
                                    id="TTTA"
                                    mx="auto"
                                    p={{base: '20px', md: '30px'}}
                                    pe="20px"
                                    minH="100vh"
                                    pt="50px"
                                >
                                    <Routes>
                                        {getRoutes(routes)}
                                        <Route
                                            path="/"
                                            element={<Navigate to="/auth/login" replace/>}
                                        />
                                    </Routes>
                                </Box>
                            ) : null}
                            <Box>
                                <Footer/>
                            </Box>
                        </Box>
                    </SidebarContext.Provider>
                </Box>
            </DeviceProvider>
    );

}
