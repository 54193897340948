import { useContext, useState, useEffect, useRef } from "react";
import { useChat } from "ai/react";
import {Box, VStack, Input, Button, Spinner, Text, HStack} from "@chakra-ui/react";
import { UserContext } from "../../../contexts/UserContext";
import ChatMessageBlocks from "./components/chatMessageBlocks";

type Block =
    | { type: "text"; content: string }
    | { type: "table"; headers: string[]; rows: string[][] }
    | { type: "list"; items: string[] };

type ParsedMessage = {
    role: "user" | "assistant";
    preamble: string;
    structuredData?: Block[];
};

export default function PopupChat() {
    const { token } = useContext(UserContext);

    const { messages, input, setInput, handleSubmit, isLoading } = useChat({
        api: "/api/chat",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    const [parsedMessages, setParsedMessages] = useState<ParsedMessage[]>([]);
    const currentMessage = useRef<ParsedMessage | null>(null); // Track the currently streaming message
    const partialJSON = useRef(""); // Buffer for incomplete JSON
    const isInsideJSON = useRef(false); // Flag to detect structured data block
    const messagesEndRef = useRef<HTMLDivElement>(null); // To auto-scroll to the bottom

    const suggestedQuestions = [
        "What was my top seller last week?",
        "Who sold the most last month?",
        "Can I see an overview of our performance this quarter?",
    ];

    useEffect(() => {
        const updatedMessages: ParsedMessage[] = [];

        messages.forEach((msg) => {
            if (msg.role === "assistant") {
                if (!currentMessage.current || currentMessage.current.preamble !== msg.content) {
                    currentMessage.current = {
                        role: "assistant",
                        preamble: "",
                        structuredData: [],
                    };
                }

                const chunks = msg.content.split("\n");
                chunks.forEach((chunk) => {
                    if (chunk.startsWith("```json")) {
                        isInsideJSON.current = true;
                        partialJSON.current = "";
                    } else if (chunk.startsWith("```") && isInsideJSON.current) {
                        isInsideJSON.current = false;
                        try {
                            const parsed = JSON.parse(partialJSON.current);
                            currentMessage.current.structuredData = parsed;
                        } catch (error) {
                            console.error("Error parsing JSON:", error);
                        }
                        partialJSON.current = "";
                    } else if (isInsideJSON.current) {
                        partialJSON.current += chunk;
                    } else {
                        currentMessage.current.preamble += chunk + "\n";
                    }
                });

                if (currentMessage.current) {
                    updatedMessages.push({ ...currentMessage.current });
                }
            } else {
                updatedMessages.push({ role: "user", preamble: msg.content });
            }
        });

        setParsedMessages(updatedMessages);

        // Auto-scroll to bottom
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    return (
        <Box display="flex" flexDirection="column" flex="1">
            {/* Messages Area */}
            <Box
                flex="1"
                overflowY="auto"
                overflowX="hidden" // Prevent horizontal scrolling
                bg="white"
                borderRadius="md"
                boxShadow="sm"
                p={4}
            >
                <VStack spacing={4} align="stretch">
                    {parsedMessages.map((msg, idx) => (
                        <Box
                            key={idx}
                            alignSelf={msg.role === "user" ? "flex-end" : "flex-start"}
                            bg={msg.role === "user" ? "blue.500" : "gray.200"}
                            color={msg.role === "user" ? "white" : "black"}
                            p={3}
                            borderRadius="lg"
                            maxWidth="100%" // Constrain message width to container
                            wordBreak="break-word" // Break long words to fit
                            whiteSpace="pre-wrap" // Preserve formatting and allow wrapping
                        >
                            {msg.preamble && <Text>{msg.preamble.trim()}</Text>}
                            {msg.structuredData &&
                                msg.structuredData.map((block, blockIdx) => (
                                    <ChatMessageBlocks key={blockIdx} block={block} />
                                ))}
                        </Box>
                    ))}

                    {/* Loading indicator */}
                    {isLoading && (
                        <Box alignSelf="flex-start">
                            <Spinner size="sm" color="blue.500" />
                            <Box mt={2} color="gray.600" fontSize="sm">
                                Assistant is typing...
                            </Box>
                        </Box>
                    )}
                    {/* Auto-scroll target */}
                    <div ref={messagesEndRef}></div>
                </VStack>
            </Box>

            {/* Input Box */}
            <Box p={4} bg="gray.50" borderTop="1px solid #e2e8f0">
                <Box
                    overflowX="auto" // Enable horizontal scrolling
                    whiteSpace="nowrap" // Ensure pills stay on one line
                    maxW="450px" // Prevent expanding beyond the container
                    p={2} // Add some padding
                    mb={4} // Margin below the pills area
                    bg="gray.100" // Light background for the pills
                    borderRadius="md" // Round corners for styling
                    boxShadow="sm"
                >
                    {suggestedQuestions.map((question, index) => (
                        <Button
                            key={index}
                            size="sm"
                            variant="outline"
                            colorScheme="brand"
                            onClick={() => setInput(question)} // Populate the input with the selected question
                            disabled={isLoading} // Disable if loading
                            mr={2} // Add spacing between pills
                        >
                            {question}
                        </Button>
                    ))}
                </Box>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                >
                    <Box display="flex" gap={2}>
                        <Input
                            placeholder="Type your question..."
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            disabled={isLoading}
                        />
                        <Button type="submit" colorScheme="blue" isLoading={isLoading}>
                            Send
                        </Button>
                    </Box>
                </form>
            </Box>
        </Box>
    );
}
