// components/ReportConfigurator.jsx
//@ts-nocheck
// ReportConfigurator.jsx
import React from "react";
import {
    Box,
    FormControl,
    FormLabel,
    Checkbox,
    Select,
    SimpleGrid,
} from "@chakra-ui/react";

// Define which fields are available to display
const AVAILABLE_COLUMNS = [
    { label: "Order ID", value: "orderId" },
    { label: "Date", value: "date" },
    { label: "Discounts", value: "discounts" },
    { label: "Gross Amount", value: "grossAmount" },
    { label: "Product Name", value: "name" }, // NEW
    { label: "Net Amount", value: "netAmount" },
    { label: "Order Number", value: "orderNumber" },
    { label: "Tax Amount", value: "taxAmount" },
    { label: "Terminal Number", value: "terminalNumber" },
    { label: "User Name", value: "userName" },
    { label: "Category Name", value: "categoryName" },
    { label: "Order Type", value: "orderType" },
];

// We can allow grouping by anything that makes sense (including "name")
const AVAILABLE_GROUPS = [
    { label: "None", value: null },
    { label: "Product Name", value: "name" },
    { label: "User Name", value: "userName" },
    { label: "Category Name", value: "categoryName" },
    { label: "Order Type", value: "orderType" },
];

export default function ReportConfigurator({ config, setConfig }) {
    // Toggle a column in or out of selectedColumns
    const handleColumnChange = (colValue) => {
        const isSelected = config.selectedColumns.includes(colValue);
        const newSelected = isSelected
            ? config.selectedColumns.filter((col) => col !== colValue)
            : [...config.selectedColumns, colValue];
        setConfig({ ...config, selectedColumns: newSelected });
    };

    // Grouping
    const handleGroupChange = (e) => {
        const newGroup = e.target.value === "null" ? null : e.target.value;
        setConfig({ ...config, groupBy: newGroup });
    };

    return (
        <Box>
            <FormControl mb={4}>
                <FormLabel>Columns to Display</FormLabel>
                <SimpleGrid columns={2} spacing={2}>
                    {AVAILABLE_COLUMNS.map((col) => (
                        <Checkbox
                            key={col.value}
                            isChecked={config.selectedColumns.includes(col.value)}
                            onChange={() => handleColumnChange(col.value)}
                        >
                            {col.label}
                        </Checkbox>
                    ))}
                </SimpleGrid>
            </FormControl>

            <FormControl>
                <FormLabel>Group By</FormLabel>
                <Select
                    value={config.groupBy || "null"}
                    onChange={handleGroupChange}
                >
                    {AVAILABLE_GROUPS.map((group) => (
                        <option key={group.value} value={group.value ?? "null"}>
                            {group.label}
                        </option>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}
