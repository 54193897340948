import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Flex,
    Switch,
    Text,
    Button,
    Tooltip,
    RadioGroup,
    Radio,
    useDisclosure,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { motion, AnimatePresence } from "framer-motion";
import DojoSettingsModal from "./DojoSettingsModal";
import SumupSettingsModal from "./SumupSettingsModal";
import useManageSiteSettings from "../../../../api/site/useManageSiteSettings";
import TeyaSettingsModal from "./TeyaSettingsModal";
import useManageTeyaSettings from "../../../../api/site/manageTeyaSettings";
import { useToast } from "@chakra-ui/react";

export default function CardMethodTable() {
    const [defaultSwitch, setDefaultSwitch] = useState("");
    const [enabledCards, setEnabledCardsState] = useState([]);
    const { setDefaultCard, setEnabledCards, getEnabledCards, getDefaultCard, setZettleTipping, getZettleTipping } = useManageSiteSettings();

    const [tippingEnabled, setTippingEnabled] = useState(false); // Add this line for the tipping setting
    const [tippingOption, setTippingOption] = useState("amount");

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [authCode, setAuthCode] = useState('');

    const [teyaAuth, setTayaAuth] = useState(false);
    const toast = useToast();
    const { logoutTeya, checkForAuth } = useManageTeyaSettings();

    useEffect(() => {
        const fetchTeyaAuth = async () => {
            let teyaAuth = await checkForAuth();
            setTayaAuth(teyaAuth);
        }
        fetchTeyaAuth();
    }, [checkForAuth]);

    useEffect(() => {
        if (defaultSwitch) {
            setDefaultCard(defaultSwitch);
        }
    }, [defaultSwitch]);

    useEffect(() => {
        if (tippingEnabled) {
            setTippingOption('amount');
            setZettleTipping(tippingEnabled, 'amount').then(r => console.log(r));
        } else {
            setZettleTipping(tippingEnabled, null).then(r => console.log(r));
        }
    }, [tippingEnabled]);

    useEffect(() => {
        const fetchEnabledCards = async () => {
            let cards = await getEnabledCards();
            setEnabledCardsState(cards);
            console.log("ENABLED CARDS", cards);
        };
        fetchEnabledCards();
    }, []);

    useEffect(() => {
        const fetchDefaultCard = async () => {
            let card = await getDefaultCard();
            setDefaultSwitch(card);
            console.log("DEFAULT CARD", card);
        };
        fetchDefaultCard();
    }, []);

    useEffect(() => {
        const fetchZettleTipping = async () => {
            let zettleTipping = await getZettleTipping();
            console.log("Zettle Tipping", zettleTipping);

            setTippingEnabled(zettleTipping.tippingEnabled);
            setTippingOption(zettleTipping.tippingType || 'amount');
        };
        fetchZettleTipping();
    }, []);

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const code = query.get('code');
        if (code) {
            setAuthCode(code);
            onOpen(); // Open the modal if the code is present
        }
    }, [onOpen]);


    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const code = query.get("code");
        const iss = query.get("iss");

        if (code && iss === "https://identity.cloud.saltpay.co/oauth/v2/oauth-anonymous") {
            setTayaAuth(true);
        }
    }, []);

    const handleSwitchChange = async (card: string, newState = false) => {
        setEnabledCardsState((prevState) => {
            let nextState;
            if (newState && !prevState.includes(card)) {
                nextState = [...prevState, card];
            } else if (!newState && prevState.includes(card)) {
                nextState = prevState.filter(enabledCard => enabledCard !== card);
            } else {
                nextState = prevState;
            }
            // Here we update server state whenever a flip switch changes
            setEnabledCards(nextState);

            return nextState;
        });
    };

    const isChecked = (card: string) => enabledCards.includes(card);

    const handleTeyaLogout = async () => {
        try {
            await logoutTeya();
            toast({
                title: "Disconnected Teya successfully. Tap Teya Settings to connect again.",
                status: "success",
            });
            onClose();
            setTayaAuth(false);
        } catch (error) {
            console.error("Error during logout:", error);
            toast({
                title: "Logout failed - please contact support.",
                status: "error",
            });
        }
    };

    return (
        <Box overflowX="auto" ml="20px" mr="20px">
            <Flex align={{ lg: 'center' }} justify={{ base: 'space-between' }} w="100%" px="20px" mb="20px">
                <Text fontSize="xl" fontWeight="600" lineHeight="100%">Card Payment Methods</Text>
            </Flex>

            <Table width="100%" size="md" p="40px" variant="striped">
                <Thead>
                    <Tr>
                        <Th>Name</Th>
                        <Th>Enabled</Th>
                        <Th>
                            Default
                            <Tooltip label="This is the method that will be used when pressing the card button on the main screen of the app" placement="right" ml="2">
                                <InfoOutlineIcon ml={1} />
                            </Tooltip>
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr>
                        <Td>Manual Card Transaction</Td>
                        <Td><Switch id="manual-enable" isChecked={isChecked('manual')} onChange={(e) => handleSwitchChange('manual', e.target.checked)} /></Td>
                        <Td><Switch id="manual-default" onChange={() => setDefaultSwitch('manual')} isChecked={defaultSwitch === 'manual'} isDisabled={!isChecked('manual')} /></Td>
                    </Tr>
                    <Tr>
                        <Td>Teya</Td>
                        <Td><Switch id="teya-enable" isChecked={isChecked('teya')} onChange={(e) => handleSwitchChange('teya', e.target.checked)} /></Td>
                        <Td><Switch id="teya-default" onChange={() => setDefaultSwitch('teya')} isChecked={defaultSwitch === 'teya'} isDisabled={!isChecked('teya')} /></Td>
                    </Tr>
                    <AnimatePresence>
                        {isChecked('teya') && (
                            <motion.tr
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.2 }}
                            >
                                <Td pl={9}>Configure Teya</Td>
                                <Td>
                                    <Flex>
                                        <TeyaSettingsModal />
                                    </Flex>
                                </Td>
                                    <Td>
                                    <Flex>
                                        {teyaAuth && (
                                        <Button onClick={() => handleTeyaLogout()} colorScheme="red">Disconnect Teya</Button>
                                            )}
                                    </Flex>

                                </Td>
                            </motion.tr>



                        )}


                    </AnimatePresence>
                    <Tr>
                        <Td>EVO Payments</Td>
                        <Td><Switch id="seamless-enable" isChecked={isChecked('evo')} onChange={(e) => handleSwitchChange('evo', e.target.checked)} /></Td>
                        <Td><Switch id="seamless-default" onChange={() => setDefaultSwitch('evo')} isChecked={defaultSwitch === 'evo'} isDisabled={!isChecked('evo')} /></Td>
                    </Tr>
                    <Tr>
                        <Td>Zettle</Td>
                        <Td><Switch id="zettle-enable" isChecked={isChecked('zettle')} onChange={(e) => handleSwitchChange('zettle', e.target.checked)} /></Td>
                        <Td><Switch id="zettle-default" onChange={() => setDefaultSwitch('zettle')} isChecked={defaultSwitch === 'zettle'} isDisabled={!isChecked('zettle')} /></Td>
                    </Tr>
                    <AnimatePresence>
                        {isChecked('zettle') && (
                            <motion.tr
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.2 }}
                            >
                                <Td pl={9}>Enable Tipping</Td>
                                <Td>
                                    <Flex>
                                        <Switch id="tipping-enable" isChecked={tippingEnabled} onChange={(e) => setTippingEnabled(e.target.checked)} />
                                    </Flex>
                                </Td>
                                <Td>
                                    <Flex direction="column" align="left">
                                        <Text mb={2}>Type:</Text>
                                        <RadioGroup value={tippingOption} onChange={(value: string) => setTippingOption(value)}>
                                            <Box mb={2}>
                                                <Radio value="percentage" isDisabled={!tippingEnabled}>Percentage</Radio>
                                                <Tooltip label="The customer will be asked to enter a percentage to tip" placement="right" ml="2">
                                                    <InfoOutlineIcon ml={1} />
                                                </Tooltip>
                                            </Box>
                                            <Box mb={2}>
                                                <Radio value="amount" isDisabled={!tippingEnabled}>Amount</Radio>
                                                <Tooltip label="The customer will be invited to enter an amount to tip" placement="right" ml="2">
                                                    <InfoOutlineIcon ml={1} />
                                                </Tooltip>
                                            </Box>
                                        </RadioGroup>
                                    </Flex>
                                </Td>
                            </motion.tr>
                        )}
                    </AnimatePresence>
                    <Tr>
                        <Td>Dojo</Td>
                        <Td><Switch id="dojo-enable" isChecked={isChecked('dojo')} onChange={(e) => handleSwitchChange('dojo', e.target.checked)} /></Td>
                        <Td><Switch id="dojo-default" onChange={() => setDefaultSwitch('dojo')} isChecked={defaultSwitch === 'dojo'} isDisabled={!isChecked('dojo')} /></Td>
                    </Tr>
                    <AnimatePresence>
                        {isChecked('dojo') && (
                            <motion.tr
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.2 }}
                            >
                                <Td pl={9}>Configure Dojo</Td>
                                <Td>
                                    <Flex>
                                        <DojoSettingsModal />
                                    </Flex>
                                </Td>
                            </motion.tr>
                        )}
                    </AnimatePresence>
                    <Tr>
                        <Td>SumUp</Td>
                        <Td><Switch id="sumup-enable" isChecked={isChecked('sumup')} onChange={(e) => handleSwitchChange('sumup', e.target.checked)} /></Td>
                        <Td><Switch id="sumup-default" onChange={() => setDefaultSwitch('sumup')} isChecked={defaultSwitch === 'sumup'} isDisabled={!isChecked('sumup')} /></Td>
                    </Tr>
                    <AnimatePresence>
                        {isChecked('sumup') && (
                            <motion.tr
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.2 }}
                            >
                                <Td pl={9}>Configure SumUp</Td>
                                <Td>
                                    <Flex>
                                        <Button onClick={onOpen} colorScheme="blue">Configure Sumup</Button>
                                        <SumupSettingsModal isOpen={isOpen} onClose={onClose} authCode={authCode} />
                                    </Flex>
                                </Td>
                            </motion.tr>
                        )}
                    </AnimatePresence>
                </Tbody>
            </Table>
        </Box>
    );
}
