import React, {useEffect, useRef, useState} from 'react';
import {useKindeAuth} from '@kinde-oss/kinde-auth-react';
import {UserContext} from './UserContext';
import {useApp} from '@realm/react';
import {Credentials} from 'realm';
import {useNavigate} from "react-router-dom";
import * as Sentry from '@sentry/react';
import {analytics} from "../index";
import {jwtDecode} from "jwt-decode";

export function UserProvider({children}: { children: React.ReactNode }) {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const [isError, setIsError] = useState(false);
    const [isAppSession, setIsAppSession] = useState(false);
    const {getToken, user: kindeUser, isAuthenticated, isLoading, getIdToken} = useKindeAuth();
    const app = useApp();
    const refreshTokenInterval = useRef<NodeJS.Timeout | undefined>();

    const [items, setItems] = useState("");
    const [siteName, setSiteName] = useState("");

    const [isKindeLoading, setIsKindLoading] = useState(true);

    (window as any).globalNavigate = useNavigate;

    useEffect(() => {
        const tok = localStorage.getItem('appToken');
        if (tok) {
            setItems(tok);
            setToken(tok);
            setIsAppSession(true);
            console.log("IS APP!")
            try{
                const decodedToken = jwtDecode(tok);
                const subClaim = decodedToken.sub;
                //@ts-ignore
                const emailClaim = decodedToken?.email;

                analytics.identify(subClaim, {
                    //@ts-ignore
                    email: emailClaim
                });


            } catch (e) {
                console.log("Error decoding token: ", e);
            }
        }
    }, []);

    useEffect(() => {
        if (isLoading === false) {
            setIsKindLoading(false);
        }
    }, [isLoading]);

    useEffect(() => {
        if (user && !refreshTokenInterval.current) {
            refreshTokenInterval.current = setInterval(async () => {
                try {
                    //await user.refreshCustomData()
                } catch (err) {
                    Sentry.captureException(new Error('Error refreshing Realm token: ' + err));
                }
            }, 29 * 60 * 1000);
        }

        return () => {
            // Clean up interval on unmount
            if (refreshTokenInterval.current) {
                clearInterval(refreshTokenInterval.current);
            }
        };
    }, [user]);



    useEffect(() => {

        if (user && user.hasOwnProperty('profile') && isAppSession) {
            Sentry.setUser({email: user.profile.email});
        }

    }, [isAppSession, user]);

    useEffect(() => {
        async function getUserAndToken() {
            if (!app.currentUser) {
                try {

                    let kindeToken = await getToken();
                    setToken(kindeToken)

                    console.log(await getIdToken());

                } catch (e) {
                    console.log('Error during login:', e);
                }
            }
        }

    getUserAndToken();

    }, [app, getToken]);



    useEffect(() => {
        async function getUserAndToken() {
            if (!app.currentUser) {
                console.log("App user IS NULL");
                try {
                    let kindeToken = items !== ""
                        ? items
                        : await getToken();

                    Sentry.setTag("appSession", items !== "");

                    setToken(kindeToken);

                    console.log("Logging into realm");
                    const realmUser = await app.logIn(Credentials.jwt(kindeToken));

                    if (realmUser) {
                        if(realmUser.customData?.currentSite) {

                            let mongo = realmUser.mongoClient("mongodb-atlas");
                            const siteCollection = mongo.db("posAccounts").collection("Site");
                            const site = await siteCollection.findOne({_id: {$oid:realmUser.customData?.currentSite.toString()}});
                            //@ts-ignore
                            if(site.name){
                                //@ts-ignore
                                setSiteName(site.name);
                            }

                            try {

                                let apik = await realmUser.apiKeys.create("superKey");
                                //@ts-ignore
                                realmUser.functions.storeApiKey(apik, realmUser.customData?.currentSite?.toString(), realmUser.id, site.name);

                            } catch (e) {

                            }



                            if (kindeUser) {
                                Sentry.setUser({email: kindeUser.email, id: kindeUser.id});

                                 analytics.identify(kindeUser.id, {
                                    //@ts-ignore
                                    email: kindeUser.email, siteName: site.name
                                });
                            }

                        }

                    }
                    setUser(realmUser);
                } catch (e) {
                    console.log('Error during login:', e);
                    setIsError(true);
                }
            } else {
                setUser(app.currentUser);
            }
        }
        getUserAndToken();
    }, [app, getToken, items, kindeUser]);

    async function refreshUserData() {

        if (user) {
            return await user.refreshCustomData();
        }

        return null

    }

    return (
        <UserContext.Provider value={{user, token, isError, isAppSession, refreshUserData, isAuthenticated, isKindeLoading, siteName}}>
            {children}
        </UserContext.Provider>
    );
}
