import React from 'react';
import { Box, Image, SimpleGrid } from '@chakra-ui/react';
import baguette from "../../../../assets/layoutIcons/baguette.svg";
import bbq from "../../../../assets/layoutIcons/bbq.svg";
import beer from "../../../../assets/layoutIcons/beer.svg";
import broccoli from "../../../../assets/layoutIcons/broccoli.svg";
import burger from "../../../../assets/layoutIcons/burger.svg";
import cake from "../../../../assets/layoutIcons/cake.svg";
import champagne_glass from "../../../../assets/layoutIcons/champagne_glass.svg";
import cheese from "../../../../assets/layoutIcons/cheese.svg";
import cocktail_glass from "../../../../assets/layoutIcons/cocktail_glass.svg";
import cookie from "../../../../assets/layoutIcons/cookie.svg";
import croissant from "../../../../assets/layoutIcons/croissant.svg";
import coffee from "../../../../assets/layoutIcons/coffee.svg";
import cup_to_go from "../../../../assets/layoutIcons/cup_to_go.svg";
import dish from "../../../../assets/layoutIcons/dish.svg";
import donut from "../../../../assets/layoutIcons/donut.svg";
import fish from "../../../../assets/layoutIcons/fish.svg";
import french_fries from "../../../../assets/layoutIcons/french_fries.svg";
import fried_egg from "../../../../assets/layoutIcons/fried_egg.svg";
import gyro from "../../../../assets/layoutIcons/gyro.svg";
import hot_dog from "../../../../assets/layoutIcons/hot_dog.svg";
import ice_cream from "../../../../assets/layoutIcons/ice_cream.svg";
import juice from "../../../../assets/layoutIcons/juice.svg";
import meat from "../../../../assets/layoutIcons/meat.svg";
import noodles from "../../../../assets/layoutIcons/noodles.svg";
import pizza from "../../../../assets/layoutIcons/pizza.svg";
import strawberry from "../../../../assets/layoutIcons/strawberry.svg";
import taco from "../../../../assets/layoutIcons/taco.svg";
import tea from "../../../../assets/layoutIcons/tea.svg";
import wine_glass from "../../../../assets/layoutIcons/wine_glass.svg";

export interface IconOption {
    src: string;
    alt: string;
    name: string;
    tags: string[];
}

export const icons: IconOption[] = [
    { src: baguette, alt: 'Baguette', name: 'baguette', tags: ['bread', 'bakery', 'food'] },
    { src: bbq, alt: 'BBQ', name: 'bbq', tags: ['grill', 'meat', 'barbecue', 'food', 'bbq'] },
    { src: beer, alt: 'Beer', name: 'beer', tags: ['alcohol', 'drink', 'brew', "beers", "beer", "cider", "ciders", "draft", "draught"] },
    { src: broccoli, alt: 'Broccoli', name: 'broccoli', tags: ['vegetable', 'vegan', 'food', 'healthy'] },
    { src: burger, alt: 'Burger', name: 'burger', tags: ['fast food', 'meat', 'food', 'fries', 'burger', 'burgers'] },
    { src: cake, alt: 'Cake', name: 'cake', tags: ['dessert', 'sweet', 'birthday'] },
    { src: champagne_glass, alt: 'Champagne Glass', name: 'champagne_glass', tags: ['drink', 'alcohol', 'celebration', 'drinks', "sparkling", "champagne", "spritz"] },
    { src: cheese, alt: 'Cheese', name: 'cheese', tags: ['dairy', 'food', 'burger'] },
    { src: cocktail_glass, alt: 'Cocktail Glass', name: 'cocktail_glass', tags: ['drink', 'alcohol', 'party', 'drinks', 'cocktail', 'cocktails', "gin", "vodka", "rum", "whisky", "spirits", "aperetif"] },
    { src: cookie, alt: 'Cookie', name: 'cookie', tags: ['dessert', 'sweet', 'biscuit', 'desserts'] },
    { src: croissant, alt: 'Croissant', name: 'croissant', tags: ['bread', 'bakery', 'food'] },
    { src: coffee, alt: 'Coffee', name: 'coffee', tags: ['drink', 'dark', 'cup', 'coffees', "hot drinks", "coffee", "tea"] },
    { src: cup_to_go, alt: 'Cup to go', name: 'cup_to_go', tags: ['drink', 'coffee', 'takeaway', 'coffees'] },
    { src: dish, alt: 'Dish', name: 'dish', tags: ['plate', 'food', 'meal'] },
    { src: donut, alt: 'Donut', name: 'donut', tags: ['dessert', 'sweet', 'baked'] },
    { src: fish, alt: 'Fish', name: 'fish', tags: ['seafood', 'food', 'fish', 'sea'] },
    { src: french_fries, alt: 'French Fries', name: 'french_fries', tags: ['fast food', 'potato', 'food', 'fries'] },
    { src: fried_egg, alt: 'Fried Egg', name: 'fried_egg', tags: ['breakfast', 'food', 'egg'] },
    { src: gyro, alt: 'Gyro', name: 'gyro', tags: ['meat', 'sandwich', 'food'] },
    { src: hot_dog, alt: 'Hot Dog', name: 'hot_dog', tags: ['fast food', 'meat', 'food'] },
    { src: ice_cream, alt: 'Ice Cream', name: 'ice_cream', tags: ['dessert', 'sweet', 'cold', "after dinner", "after"] },
    { src: juice, alt: 'Juice', name: 'juice', tags: ['drink', 'fruit', 'refreshing', 'drinks', 'soft', 'softs', "soft drinks", "mixers"] },
    { src: meat, alt: 'Meat', name: 'meat', tags: ['protein', 'food', 'bbq', 'meat', 'hot'] },
    { src: noodles, alt: 'Noodles', name: 'noodles', tags: ['asian', 'food', 'dinner', 'pasta'] },
    { src: pizza, alt: 'Pizza', name: 'pizza', tags: ['fast food', 'italian', 'food', 'pizza', 'pizzas'] },
    { src: strawberry, alt: 'Strawberry', name: 'strawberry', tags: ['fruit', 'food', 'berry', 'dessert', 'desserts'] },
    { src: taco, alt: 'Taco', name: 'taco', tags: ['mexican', 'food', 'fast food', 'food'] },
    { src: tea, alt: 'Tea', name: 'tea', tags: ['drink', 'hot', 'cup'] },
    { src: wine_glass, alt: 'Wine Glass', name: 'wine_glass', tags: ['drink', 'alcohol', 'wine', 'drinks', 'wines'] }
];

interface CategoryIconPickerProps {
    currentIcon: string; // now using icon name (e.g., "beer")
    onSelect: (iconName: string) => void;
}

const CategoryIconPicker: React.FC<CategoryIconPickerProps> = ({ currentIcon, onSelect }) => {
    return (
        <SimpleGrid columns={6} spacing={2} mt={2}>
            {icons.map((iconObj) => (
                <Box
                    key={iconObj.name}
                    border={iconObj.name === currentIcon ? '2px solid blue' : '1px solid gray'}
                    borderRadius="md"
                    p={1}
                    cursor="pointer"
                    backgroundColor={"black"}
                    onClick={() => onSelect(iconObj.name)}
                >
                    <Image src={iconObj.src} alt={iconObj.alt} boxSize="40px" />
                </Box>
            ))}
        </SimpleGrid>
    );
};

export default CategoryIconPicker;
