import {
    Button,
    ButtonGroup,
    useRadioGroup,
    Flex,
    Radio,
    RadioGroup,
    Spinner,
    HStack,
    Box,
    useRadio,
    Text
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { subDays, startOfWeek, endOfWeek, startOfDay, subWeeks, endOfDay, addDays } from 'date-fns';
import Card from 'components/card/Card';
import {useState, forwardRef} from "react";

import "react-datepicker/dist/react-datepicker.css";
import {useGetSalesByUser} from "../../../../api/reports/getSalesByUser";
import {getRolloverDates} from "../../../../helpers/getRolloverDates";

import { Input } from "@chakra-ui/react";
import CustomDateSelector from "../../../../components/reportDateSelector";
import RefundsTable from "./components/RefundsTable";
import {useGetRefunds} from "../../../../api/reports/getRefunds";

// @ts-ignore
const CustomInput = forwardRef<HTMLInputElement, any>(({ value, onClick }, ref) => (
    <Input onClick={onClick} ref={ref} value={value} />
));

// @ts-ignore
function Segment(props) {
    const { getInputProps, getCheckboxProps } = useRadio(props);

    const input = getInputProps();
    const checkbox = getCheckboxProps();

    return (
        <Box as="label">
            <input {...input} />
            <Box
                {...checkbox}
                cursor="pointer"
                borderWidth="1px"
                borderRadius="md"
                boxShadow="md"
                _checked={{
                    bg: "blue.600",
                    color: "white",
                    borderColor: "blue.600",
                }}
                _focus={{
                    boxShadow: "outline",
                }}
                px={5}
                py={3}
            >
                {props.children}
            </Box>
        </Box>
    );
}
export default function RefundsReport() {
    const dateTimeToday = getRolloverDates(1, startOfDay(new Date()));
    const [startDate, setStartDate] = useState(dateTimeToday[0]);
    const [endDate, setEndDate] = useState(dateTimeToday[1]);
    const [{ data: orders, isLoading, isError, refetch }] = useGetRefunds(startDate.toISOString(), endDate.toISOString())

    type CommonDatesKeys = 'Yesterday' | 'Today' | 'This Week' | 'Last Week';
    type CommonDates = { [K in CommonDatesKeys]: [Date, Date] }

    const startDateLastWeek = startOfWeek(subWeeks(new Date(), 1));
    const endDateLastWeek = endOfDay(addDays(endOfWeek(subWeeks(new Date(), 1)), 1));

    const commonDates: CommonDates = {
        'Today': getRolloverDates(1, new Date()),
        'Yesterday': getRolloverDates(1, subDays(new Date(), 1)),
        'This Week': [getRolloverDates(1, startOfWeek(new Date()))[0], new Date()],
        'Last Week': [getRolloverDates(1, startDateLastWeek)[0], getRolloverDates(1, endDateLastWeek)[0]]
    };

    //@ts-ignore
    const handleCommonDateClick = (label: CommonDatesKeys, dates: Date[]) => {
        setStartDate(dates[0]);
        setEndDate(dates[1]);

        setTimeout(() => {
            refetch({
                query: {
                    closedDate_gt: dates[0].toISOString(),
                    closedDate_lt: dates[1].toISOString(),
                },
            });
        }, 0);
    };

    const options = Object.keys(commonDates);
    const { getRootProps, getRadioProps } = useRadioGroup({
        name: "framework",
        defaultValue: "Today",
        onChange: (value: CommonDatesKeys) => handleCommonDateClick(value, commonDates[value]),
    });

    const group = getRootProps();

    // @ts-ignore
    // @ts-ignore
    return (
        <Flex direction='column' pt={{ sm: '125px', lg: '75px' }}>
            <CustomDateSelector handleDateChange={({startDate, endDate}: {startDate: Date, endDate: Date}) => {
                console.log(startDate)
                console.log(endDate)
                setStartDate(startDate);
                setEndDate(endDate);

                setTimeout(() => {
                    refetch({
                        query: {
                            closedDate_gt: startDate.toISOString(),
                            closedDate_lt: endDate.toISOString(),
                        },
                    });
                }, 0);
            }} />
            <Card px='0px'>
                {isLoading &&
                    <Spinner/>
                }
                {orders?.length > 0 &&
                    <RefundsTable key={startDate.getTime()} tableData={orders} startDate={startDate} endDate={endDate}/>
                }
            </Card>
        </Flex>
    );
}
