import {
    Button,
    ButtonGroup,
    useRadioGroup,
    Flex,
    Radio,
    RadioGroup,
    Spinner,
    HStack,
    Box,
    useRadio,
    Text
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { subDays, startOfWeek, endOfWeek, startOfDay, subWeeks, endOfDay, addDays } from 'date-fns';
import Card from 'components/card/Card';
import {useState, forwardRef} from "react";

import "react-datepicker/dist/react-datepicker.css";
import GratuitiesTable from "./components/GratuitiesTable";
import {useGetGratuities} from "../../../../api/reports/getGratuities";
import {getRolloverDates} from "../../../../helpers/getRolloverDates";

import { Input } from "@chakra-ui/react";
import CustomDateSelector from "../../../../components/reportDateSelector";


export default function GratuitiesReport() {
    const dateTimeToday = getRolloverDates(1, startOfDay(new Date()));
    const [startDate, setStartDate] = useState(dateTimeToday[0]);
    const [endDate, setEndDate] = useState(dateTimeToday[1]);
    const [{ data: orders, isLoading, isError, refetch }] = useGetGratuities(startDate.toISOString(), endDate.toISOString())

    type CommonDatesKeys = 'Yesterday' | 'Today' | 'This Week' | 'Last Week';
    type CommonDates = { [K in CommonDatesKeys]: [Date, Date] }

    const startDateLastWeek = startOfWeek(subWeeks(new Date(), 1));
    const endDateLastWeek = endOfDay(addDays(endOfWeek(subWeeks(new Date(), 1)), 1));

    const commonDates: CommonDates = {
        'Today': getRolloverDates(1, new Date()),
        'Yesterday': getRolloverDates(1, subDays(new Date(), 1)),
        'This Week': [getRolloverDates(1, startOfWeek(new Date()))[0], new Date()],
        'Last Week': [getRolloverDates(1, startDateLastWeek)[0], getRolloverDates(1, endDateLastWeek)[0]]
    };

    //@ts-ignore
    const handleCommonDateClick = (label: CommonDatesKeys, dates: Date[]) => {
        setStartDate(dates[0]);
        setEndDate(dates[1]);

        setTimeout(() => {
            refetch({
                query: {
                    closedDate_gt: dates[0].toISOString(),
                    closedDate_lt: dates[1].toISOString(),
                },
            });
        }, 0);
    };

    const options = Object.keys(commonDates);
    const { getRootProps, getRadioProps } = useRadioGroup({
        name: "framework",
        defaultValue: "Today",
        onChange: (value: CommonDatesKeys) => handleCommonDateClick(value, commonDates[value]),
    });

    const group = getRootProps();

    // @ts-ignore
    // @ts-ignore
    return (
        <Flex direction='column' pt={{ sm: '125px', lg: '75px' }}>
            <CustomDateSelector handleDateChange={({startDate, endDate}: {startDate: Date, endDate: Date}) => {
                setStartDate(startDate);
                setEndDate(endDate);

                setTimeout(() => {
                    refetch({
                        query: {
                            closedDate_gt: startDate.toISOString(),
                            closedDate_lt: endDate.toISOString(),
                        },
                    });
                }, 0);
            }} />
            <Card px='0px'>
                {isLoading &&
                    <Spinner/>
                }
                {orders?.length > 0 &&
                    <GratuitiesTable key={startDate.getTime()} tableData={orders} startDate={startDate} endDate={endDate} />
                }
            </Card>
        </Flex>
    );
}
