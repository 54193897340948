import './assets/css/App.css';
import {Routes, Route, Navigate, useNavigate, useLocation} from 'react-router-dom';
import {} from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import {
    ChakraProvider, Spinner,
    // extendTheme
} from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import {useContext, useEffect, useState} from 'react';
import {useAppNavigation} from "./hooks/useAppNavigation";
import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";
import Ably from "ably/callbacks";
import {AblyProvider} from "ably/react";
import {DeviceProvider} from "./contexts/DeviceContext";
import {UserProvider} from "./contexts/UserProvider";
import {UserContext} from "./contexts/UserContext";
import {analytics} from "./index";
import visualEditing from "./sanity/visualEditing"
import TeyaAppAuth from "./views/teyaAppAuth";

// Chakra imports


export default function Main() {
    // eslint-disable-next-line
    const [currentTheme, setCurrentTheme] = useState(initialTheme);
    useAppNavigation();

    const location = useLocation();

    useEffect(() => {
        analytics.page();
    }, [location]);

    const client = new ApolloClient({
        uri: 'https://eu-west-1.aws.realm.mongodb.com/api/client/v2.0/app/application-0-trznh/graphql',
        cache: new InMemoryCache(),

    });

    const {token} = useContext(UserContext);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        console.log("Token effect");

        if (token !== null) {
            setLoading(false);
        }
    }, [token]);

   // visualEditing();


        return (
            <ChakraProvider theme={currentTheme}>
                <ApolloProvider client={client}>
                            <Routes>
                                <Route path="auth/*" element={<AuthLayout/>}/>
                                <Route
                                    path="admin/*"
                                    element={
                                        <AdminLayout theme={currentTheme} setTheme={setCurrentTheme}/>
                                    }
                                />
                                <Route path="/teyaappauth" element={<TeyaAppAuth />}/>
                                <Route path="/" element={<Navigate to="/admin" replace/>}/>
                            </Routes>

                </ApolloProvider>
            </ChakraProvider>
        );
}
