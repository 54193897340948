import React, { useState, useMemo } from "react";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import { Box, Heading, Spinner } from "@chakra-ui/react";
import { startOfDay } from "date-fns";
import { getRolloverDates } from "helpers/getRolloverDates";
import {useGetSalesData} from "../../../../api/reports/getSalesData";

// Define your column schema
export const ALL_COLUMNS = [
    { label: "Order ID", value: "orderId" },
    { label: "Date", value: "date" },
    { label: "Discounts", value: "discounts" },
    { label: "Gross Amount", value: "grossAmount" },
    { label: "Product Name", value: "name" },
    { label: "Net Amount", value: "netAmount" },
    { label: "Order Number", value: "orderNumber" },
    { label: "Tax Amount", value: "taxAmount" },
    { label: "Terminal Number", value: "terminalNumber" },
    { label: "User Name", value: "userName" },
    { label: "Category Name", value: "categoryName" },
    { label: "Order Type", value: "orderType" },
];

// And a set of numeric columns (for summing, etc.)
export const NUMERIC_COLUMNS = new Set(["discounts", "grossAmount", "netAmount", "taxAmount"]);

export default function SalesPivotTableExample() {
    const dateTimeToday = getRolloverDates(1, startOfDay(new Date()));
    const [startDate, setStartDate] = useState(dateTimeToday[0]);
    const [endDate, setEndDate] = useState(dateTimeToday[1]);

    // Fetch sales data using your custom hook
    const [{ data: orders, isLoading, isError, refetch }] = useGetSalesData(
        startDate.toISOString(),
        endDate.toISOString()
    );

    // Ensure we have an array of data
    const filteredData = useMemo(() => orders || [], [orders]);

    // **Always call hooks unconditionally**
    const [pivotState, setPivotState] = useState<any>({
        data: filteredData,
        rows: ["name"], // group by Product Name by default
        cols: ["terminalNumber"], // group columns by Terminal Number
        aggregatorName: "Sum", // aggregate using Sum
        vals: ["grossAmount", "netAmount"],
        rendererName: "Table",
    });

    // Now you can do early returns:
    if (isLoading) {
        return <Spinner />;
    }
    if (isError) {
        return <Box>Error loading sales data.</Box>;
    }

    return (
        <Box p={6}>
            <Heading size="lg" mb={4}>
                Sales Pivot Table
            </Heading>
            <PivotTableUI
                data={filteredData}
                onChange={(s: any) => setPivotState(s)}
                {...pivotState}
            />
        </Box>
    );
}
