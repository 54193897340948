// Chakra imports
import {Box, Button, Flex, FormControl, FormLabel, Heading, Input, useColorModeValue, Icon, Text, useMediaQuery} from '@chakra-ui/react';

// Custom components
import DefaultAuth from 'layouts/auth/variants/Default';

// Assets
import illustration from 'assets/img/auth/auth.png';

import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import {FcGoogle} from 'react-icons/fc';
import {FaApple} from "react-icons/fa";
import {HSeparator} from "../../components/separator/Separator";
import Seamless from "../../components/sidebar/components/Seamless.svg"

function LoginForm() {

    const googleBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.200');
    const googleText = useColorModeValue('navy.700', 'white');
    const googleHover = useColorModeValue(
        {bg: 'gray.200'},
        {bg: 'whiteAlpha.300'},
    );
    const googleActive = useColorModeValue(
        {bg: 'secondaryGray.300'},
        {bg: 'whiteAlpha.200'},
    );


    const appleBg = useColorModeValue('black', 'black');
    const appleText = useColorModeValue('white', 'white');
    const appleHover = useColorModeValue(
        {bg: 'gray.900'},
        {bg: 'gray.900'},
    );
    const appleAvtice = useColorModeValue(
        {bg: 'secondaryGray.800'},
        {bg: 'secondaryGray.800'},
    );

    const appleConnection = process.env.REACT_APP_APPLE_CONNECTION;
    const googleConnection = process.env.REACT_APP_GOOGLE_CONNECTION;

    const [email, setEmail] = useState('');

    const [hideForm, setHideForm] = useState(false);

    const {login, register, getToken} = useKindeAuth();
    const navigate = useNavigate();

    const [isSmallerThanMd] = useMediaQuery("(max-width: 48em)");

    // eslint-disable-next-line no-restricted-globals
    const query = new URLSearchParams(location.search);
    const loggedOut = query.get('loggedOut')

    const webSignup = query.get('web');

    useEffect(() => {

        if (webSignup === 'true') {
            setHideForm(true);
        }

        const loginToDash = async () => {
            if (webSignup === 'true') {
                    await login();
            }
        }

        loginToDash();

    }, [webSignup, login]);

    useEffect(() => {
        const checkIfTokenExists = async () => {

            let accessToken = null;

            try {
                accessToken = await getToken();
            } catch (e) {

            }
            if (accessToken) {
                navigate('/admin/dashboard');
            } else {
                //  await login();
            }

        }

        if (loggedOut !== 'y') {
            checkIfTokenExists()
        }

    }, [login, getToken, loggedOut]);


    // Chakra color mode
    const textColor = useColorModeValue('navy.700', 'white');
    const brandStars = useColorModeValue('brand.500', 'brand.400');
    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                w='100%'
                maxW='max-content'
                mx={{base: 'auto', lg: '0px'}}
                me='auto'
                h='100%'
                alignItems='start'
                justifyContent='center'
                mb={{base: '30px', md: '60px', lg: '120px', xl: '60px'}}
                px={{base: '25px', md: '0px'}}
                mt={{base: '40px', lg: '18vh', xl: '22vh'}}
                flexDirection='column'>
                {isSmallerThanMd ?
                    <Flex justifyContent='center' alignItems='center' width='100%' mb={10}>

                            <img src={Seamless} alt="description" width={"60%"} />

                    </Flex>
                    : null}
                <Box me='auto' mb='34px'>
                    <Heading color={textColor} fontSize='36px' mb='16px'>
                        Log in to the backoffice
                    </Heading>

                </Box>
                <Flex
                    zIndex='2'
                    direction='column'
                    w={{base: '100%', lg: '420px'}}
                    maxW='100%'
                    background='transparent'
                    borderRadius='15px'
                    mx={{base: 'auto', lg: 'unset'}}
                    me='auto'
                    mb={{base: '20px', md: 'auto'}}>



                    <form onSubmit={(e) => {
                        e.preventDefault();
                        login({
                            authUrlParams: {
                                connection_id: "conn_018f086a6e487c867201eef1763d2406",
                                login_hint: email ? email : ''
                            }
                        });
                    }}>

                    <FormControl>
                        <FormLabel
                            display="flex"
                            ms="4px"
                            fontSize="sm"
                            fontWeight="500"
                            color={textColor}
                            mb="8px"
                        >
                            Email
                        </FormLabel>
                        <Input
                            isRequired={true}
                            variant="auth"
                            fontSize="sm"
                            ms={{base: '0px', md: '0px'}}
                            type="email"
                            placeholder="hello@seamlessserve.co.uk"
                            mb="24px"
                            fontWeight="500"
                            size="lg"
                            onChange={(e) => setEmail(e.target.value)}
                        />

                    </FormControl>


                    <FormControl>
                        <Button type="submit"  fontSize='14px' variant='brand' fontWeight='500' w='100%' h='50' mb='24px'>
                            Login
                        </Button>
                    </FormControl>

                    </form>

                    <Flex
                        zIndex="2"
                        direction="column"
                        w={{base: '100%', md: '420px'}}
                        maxW="100%"
                        background="transparent"
                        borderRadius="15px"
                        mx={{base: 'auto', lg: 'unset'}}
                        me="auto"
                        mb={{base: '20px', md: 'auto'}}
                    >

                        <Flex align="center" mb="25px">
                            <HSeparator />
                            <Text color="gray.400" mx="14px">
                                or
                            </Text>
                            <HSeparator />
                        </Flex>

                        <Button
                            fontSize="sm"
                            me="0px"
                            mb="26px"
                            py="15px"
                            h="50px"
                            borderRadius="16px"
                            bg={googleBg}
                            color={googleText}
                            fontWeight="500"
                            _hover={googleHover}
                            _active={googleActive}
                            _focus={googleActive}
                            onClick={() => login({
                                authUrlParams: {
                                    connection_id: googleConnection
                                }
                            })}
                        >
                            <Icon as={FcGoogle} w="20px" h="20px" me="10px"/>
                            Sign in with Google
                        </Button>

                        <Button
                            fontSize="sm"
                            me="0px"
                            mb="26px"
                            py="15px"
                            h="50px"
                            borderRadius="16px"
                            bg={appleBg}
                            color={appleText}
                            fontWeight="500"
                            _hover={appleHover}
                            _active={appleAvtice}
                            _focus={appleAvtice}
                            onClick={() => login({
                                authUrlParams: {
                                    connection_id: "conn_018f086a6e48881c732e81bfcf5235be"
                                }
                            })}
                        >
                            <Icon as={FaApple} w="20px" h="20px" me="10px"/>
                            Sign in with Apple
                        </Button>

                    </Flex>

                </Flex>
            </Flex>
        </DefaultAuth>
    );
}

export default LoginForm;
