import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../contexts/UserContext";
import {gql, useLazyQuery} from "@apollo/client";
import {posClient} from "../apolloClients/posClient";
import {useApp} from "@realm/react";


const useManageTeyaSettings = () => {
    const app = useApp();
    const {token} = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    let mongo: { db: (arg0: string) => { (): any; new(): any; collection: { (arg0: string): any; new(): any; }; }; };

    const {user} = useContext(UserContext);

    const updateUser = () => {
        if (user) {
            mongo = user.mongoClient("mongodb-atlas");
        }
    };

    updateUser();

    const toObjectId = (idString: string): {} => {
        return {'$oid': idString};
    };

    const checkForAuth = async () => {
        if (!user) {
            setTimeout(updateUser, 2000);
            return;
        }

        const siteCollection = mongo.db("posAccounts").collection("Site");
        const siteSettingCollection = mongo.db("posAccounts").collection("SiteSetting");

        const currentSiteId = app.currentUser.customData.currentSite.toString();

        try {
            // Fetch the current site
            const site = await siteCollection.findOne({ _id: toObjectId(currentSiteId) });

            if (!site?.setting) {
                console.error("Site setting ID not found.");
                return false;
            }

            const siteSettingId = site.setting;

            // Fetch the site settings to check for the auth token
            const siteSetting = await siteSettingCollection.findOne({ _id: { $oid: siteSettingId.toString() } });

            if (!siteSetting?.teyaSettings?.authToken) {
                console.log("Auth token not found in the database.");
                return false;
            }

            console.log("Auth token found:", siteSetting.teyaSettings.authToken);
            return true;
        } catch (err) {
            console.error("Error checking for auth token:", err);
            return false;
        }
    };

    const getAuth = async () => {
        if (!user) {
            setTimeout(updateUser, 2000);
            return;
        }

        const siteCollection = mongo.db("posAccounts").collection("Site");
        const siteSettingCollection = mongo.db("posAccounts").collection("SiteSetting");

        const currentSiteId = app.currentUser.customData.currentSite.toString();

        try {
            // Fetch the current site
            const site = await siteCollection.findOne({ _id: toObjectId(currentSiteId) });

            if (!site?.setting) {
                console.error("Site setting ID not found.");
                return false;
            }

            const siteSettingId = site.setting;

            // Fetch the site settings to check for the auth token
            const siteSetting = await siteSettingCollection.findOne({ _id: { $oid: siteSettingId.toString() } });

            if (!siteSetting?.teyaSettings?.authToken) {
                console.log("Auth token not found in the database.");
                return "";
            }

            //console.log("Auth token found:", siteSetting.teyaSettings.authToken);
            return siteSetting.teyaSettings.authToken;
        } catch (err) {
            console.error("Error checking for auth token:", err);
            return "";
        }
    };

    const setTeyaTokens = async (authToken: string, refreshToken: string, authTokenExpiry: number, refreshTokenExpiry: number) => {

        if (!user) {
            setTimeout(updateUser, 2000);
            return;
        }

        const siteCollection = mongo.db("posAccounts").collection("Site");
        const siteSettingCollection = mongo.db("posAccounts").collection("SiteSetting");

        let siteSettingId;
        const currentSiteId = app.currentUser.customData.currentSite.toString();


        const site = await siteCollection.findOne({_id: toObjectId(currentSiteId)});

        const authExpiryDate = authTokenExpiry ? new Date(Date.now() + authTokenExpiry * 1000) : new Date(Date.now() + 900 * 1000);
        const refExpiryDate = refreshTokenExpiry ? new Date(Date.now() + refreshTokenExpiry * 1000) : new Date(Date.now() + 900 * 1000);
        siteSettingId = site.setting;

        try {
            await siteSettingCollection.updateOne(
                {_id: {$oid: siteSettingId.toString()}},
                {$set: {
                        'teyaSettings.authToken': authToken,
                        'teyaSettings.refreshToken': refreshToken,
                        'teyaSettings.authTokenExpiry': {$date : {$numberLong : authExpiryDate.getTime().toString()}},
                        'teyaSettings.refreshTokenExpiry': {$date : {$numberLong :refExpiryDate.getTime().toString()}}
                    }}
            );
        } catch (err) {
            console.error(err);

        }
    };

    const setTeyaTerminal = async (
        storeId: string, terminalId: string
    ) => {
        if (!user) {
            setTimeout(updateUser, 2000);
            return;
        }

        const siteCollection = mongo.db("posAccounts").collection("Site");
        const siteSettingCollection = mongo.db("posAccounts").collection("SiteSetting");

        const currentSiteId = app.currentUser.customData.currentSite.toString();
        const site = await siteCollection.findOne({ _id: toObjectId(currentSiteId) });

        const siteSettingId = site?.setting;

        if (!siteSettingId) {
            console.error('Site setting ID not found.');
            return;
        }

        try {

            await siteSettingCollection.updateOne(
                { _id: { $oid: siteSettingId.toString() } },
                { $set: { 'teyaSettings.terminalId': terminalId, 'teyaSettings.storeId': storeId } }
            );

        } catch (err) {
            console.error(err);
        }
    };

    const logoutTeya = async () => {
        if (!user) {
            setTimeout(updateUser, 2000);
            return;
        }

        const siteCollection = mongo.db("posAccounts").collection("Site");
        const siteSettingCollection = mongo.db("posAccounts").collection("SiteSetting");

        const currentSiteId = app.currentUser.customData.currentSite.toString();
        const site = await siteCollection.findOne({ _id: toObjectId(currentSiteId) });

        const siteSettingId = site?.setting;

        if (!siteSettingId) {
            console.error("Site setting ID not found.");
            return;
        }

        try {
            // Fetch the tokens
            const siteSetting = await siteSettingCollection.findOne({ _id: { $oid: siteSettingId.toString() } });
            const authToken = siteSetting?.teyaSettings?.authToken;
            const refreshToken = siteSetting?.teyaSettings?.refreshToken;

            if (!authToken || !refreshToken) {
                console.error("Tokens not found.");
                return;
            }

            // Call API route to revoke tokens
            const response = await fetch("/api/teya/revoke-tokens", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ authToken, refreshToken }),
            });

            const data = await response.json();
            if (!response.ok) {
                console.error("Failed to revoke tokens:", data.error);
                return;
            }

            console.log("Tokens revoked successfully.");

            // Clear tokens from database
            await siteSettingCollection.updateOne(
                { _id: { $oid: siteSettingId.toString() } },
                { $unset: { "teyaSettings.authToken": "", "teyaSettings.refreshToken": "" } }
            );

            console.log("Tokens removed from database.");
        } catch (err) {
            console.error("Error during logout:", err);
        }
    };



    return {
        setTeyaTokens,
        setTeyaTerminal,
        logoutTeya,
        checkForAuth,
        getAuth,
        loading,
        error,
    };
};

export default useManageTeyaSettings;
