import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TeyaAppAuthRedirect = () => {
    const location = useLocation();

    useEffect(() => {
        // Parse the query parameters from the current URL
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('code');

        if (code) {
            // Construct the URL using your custom URL scheme
            const redirectUrl = `seamlessTeya://auth-callback?code=${encodeURIComponent(code)}`;

            // Optionally, include other parameters if needed:
            // const state = searchParams.get('state');
            // if (state) {
            //   redirectUrl += `&state=${encodeURIComponent(state)}`;
            // }

            console.log("Redirecting to:", redirectUrl);
            // Redirect to the custom URL scheme
            window.location.href = redirectUrl;
        } else {
            console.error("No auth code found in the query parameters.");
        }
    }, [location.search]);

    return (
        <div style={{ padding: '2rem', textAlign: 'center' }}>
            <h2>Processing Authentication...</h2>
            <p>Please wait while we redirect you to the app.</p>
        </div>
    );
};

export default TeyaAppAuthRedirect;
