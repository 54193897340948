// common/CustomDateSelector.js

import {useState, useRef, SetStateAction, forwardRef, useMemo} from "react";
import {subDays, startOfWeek, endOfWeek, startOfDay, subWeeks, endOfDay, addDays} from 'date-fns';
import SegmentedControl from 'components/segmentedControl/index';
import {Box, Card, HStack, Input, VStack} from "@chakra-ui/react";
import { getRolloverDates } from "helpers/getRolloverDates";
import DatePicker from "react-datepicker";
import {startOfMonth, endOfMonth, subMonths} from 'date-fns';

const CustomInput = forwardRef<HTMLInputElement, any>(({value, onClick}, ref) => (
    <Input onClick={onClick} ref={ref} value={value}/>
));

// @ts-ignore
const DatePickerWithTime = ({selectedDate, onChangeDate}) => {
    return (
        <VStack spacing={1} alignItems={'start'}>
            <DatePicker
                dateFormat="dd/MM/yyyy"
                customInput={<CustomInput/>}
                selected={selectedDate}
                onChange={onChangeDate}
            />
            {/* <Text fontSize="lg" align="left" ml={4}>
                {selectedDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
            </Text> */}
        </VStack>
    );
};

// @ts-ignore
const CustomDateSelector = ({ handleDateChange, periods = "weeks" }) => {
    const dateTimeToday = getRolloverDates(1, startOfDay(new Date()));
    const [startDate, setStartDate] = useState(dateTimeToday[0]);
    const [endDate, setEndDate] = useState(dateTimeToday[1]);

    const [selectedSegment, setSelectedSegment] = useState(0);

    const startThisWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
    const startDateLastWeek = startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 });
    const endDateLastWeek = endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 });

    const todayRef = useRef();
    const yesterdayRef = useRef();
    const thisWeekRef = useRef();
    const lastWeekRef = useRef();
    const thisMonthRef = useRef();
    const lastMonthRef = useRef();

    let commonDates = {};

    const segments = useMemo(() => {
        const defaultSegments = [
            {
                label: "Today",
                value: "Today",
                ref: todayRef
            },
            {
                label: "Yesterday",
                value: "Yesterday",
                ref: yesterdayRef
            },
            {
                label: "This Week",
                value: "This Week",
                ref: thisWeekRef
            },
            {
                label: "Last Week",
                value: "Last Week",
                ref: lastWeekRef
            }
        ];

        if (periods === "months") {
            return [
                {
                    label: "This Week",
                    value: "This Week",
                    ref: thisWeekRef
                },
                {
                    label: "Last Week",
                    value: "Last Week",
                    ref: lastWeekRef
                },
                {
                    label: "This Month",
                    value: "This Month",
                    ref: thisMonthRef
                },
                {
                    label: "Last Month",
                    value: "Last Month",
                    ref: lastMonthRef
                }
            ];
        } else {
            return defaultSegments;
        }
    }, [periods]);

    if(periods === "weeks") {

        commonDates = {
            'Today': getRolloverDates(1, new Date()),
            'Yesterday': getRolloverDates(1, subDays(new Date(), 1)),
            'This Week': [getRolloverDates(1, startThisWeek)[0], new Date()],
            'Last Week': [
                getRolloverDates(1, startDateLastWeek)[0],
                getRolloverDates(1, addDays(endDateLastWeek, 1))[0]
            ]
        };

    }

    if(periods === "months") {

        const startThisMonth = startOfMonth(new Date());
        const startLastMonth = startOfMonth(subMonths(new Date(), 1));
        const endLastMonth = endOfMonth(subMonths(new Date(), 1));

        commonDates = {
            'This Week': [getRolloverDates(1, startThisWeek)[0], new Date()],
            'Last Week': [
                getRolloverDates(1, startDateLastWeek)[0],
                getRolloverDates(1, addDays(endDateLastWeek, 1))[0]
            ],
            'This Month': [getRolloverDates(1, startThisMonth)[0], new Date()],
            'Last Month': [
                getRolloverDates(1, startLastMonth)[0],
                getRolloverDates(1, addDays(endLastMonth, 1))[0]
            ]
        };

    }

    const handleCommonDateClick = (label: keyof typeof commonDates, dates: Date[]) => {

        setStartDate(dates[0]);
        setEndDate(dates[1]);

        handleDateChange({ startDate: dates[0], endDate: dates[1] });

        setSelectedSegment(segments.findIndex(s => s.value === label));
    };

    const handleStartDateChange = (date: Date) => {
        const adjustedStartDate = getRolloverDates(1, date)[0];
        setStartDate(adjustedStartDate);
        handleDateChange({ startDate: adjustedStartDate, endDate });
        setSelectedSegment(-1);
    };

    const handleEndDateChange = (date: Date) => {
        const adjustedEndDate = getRolloverDates(1, date)[1];
        setEndDate(adjustedEndDate);
        handleDateChange({ startDate, endDate: adjustedEndDate });
        setSelectedSegment(-1);
    };

    return (
        <Card px={4} mb={4} pt={4}>
            <Box
                display={{ base: "block", 'lg': "flex" }}
            >
                <Box mr={4} mb={4}>
                    <SegmentedControl
                        name="group-2"
                        callback={(val, index) => handleCommonDateClick(val as keyof typeof commonDates, commonDates[val as keyof typeof commonDates])}
                        controlRef={useRef()}
                        defaultIndex={0}
                        segments={segments}
                        selectedSegment={selectedSegment}
                    />
                </Box>
                <HStack spacing={4} pb={4} mx={2}>
                    <DatePickerWithTime selectedDate={startDate} onChangeDate={(date: Date) => handleStartDateChange(date)} />
                    <DatePickerWithTime selectedDate={endDate} onChangeDate={(date: Date) => handleEndDateChange(date)} />
                </HStack>
            </Box>
        </Card>
    );
};

export default CustomDateSelector;
