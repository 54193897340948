import React, { useState, useEffect } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Select,
    Text,
    Box,
    Alert,
    AlertIcon,
    VStack,
    Spinner,
    FormControl,
} from "@chakra-ui/react";
import axios from "axios";
import useManageTeyaSettings from "../../../../api/site/manageTeyaSettings";
import { ReactComponent as TeyaIcon } from "../../../../assets/svg/teya.svg";

export default function TeyaSettingsModal() {
    const [isOpen, setIsOpen] = useState(false);
    const [authCode, setAuthCode] = useState("");
    const [accessToken, setAccessToken] = useState("");
    const [refreshToken, setRefreshToken] = useState("");
    const [stores, setStores] = useState([]);
    const [selectedStore, setSelectedStore] = useState("");
    const [terminals, setTerminals] = useState([]);
    const [selectedTerminal, setSelectedTerminal] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [presetMessage, setPresetMessage] = useState("");
    const { setTeyaTokens, setTeyaTerminal, getAuth } = useManageTeyaSettings();

    const handleOpen = () => setIsOpen(true);
    const handleClose = () => {
        setIsOpen(false);
        resetState(); // Reset the state when modal is closed
    };

    const resetState = () => {
        setAuthCode("");
        setAccessToken("");
        setRefreshToken("");
        setStores([]);
        setSelectedStore("");
        setTerminals([]);
        setSelectedTerminal("");
        setPresetMessage("");
        setError("");
    };


    const initiateAuthorization = async () => {
        try {
            const response = await fetch("/api/teya/oauth-url");
            const { oauthUrl, codeVerifier } = await response.json();
            sessionStorage.setItem("code_verifier", codeVerifier);
            window.location.href = oauthUrl; // Redirect user to Teya OAuth flow
        } catch (err) {
            setError("Failed to initialize authorization. Please try again.");
            console.error(err);
        }
    };

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const code = query.get("code");
        const iss = query.get("iss");

        if (code && iss === "https://identity.cloud.saltpay.co/oauth/v2/oauth-anonymous") {
            handleOpen(); // Open the modal when redirected back with an auth code
            setAuthCode(code);
            exchangeCodeForToken(code);
            const newUrl = window.location.origin + window.location.pathname;
            window.history.replaceState(null, "", newUrl);
        }
    }, []);

    const exchangeCodeForToken = async (code: string) => {
        try {
            const codeVerifier = sessionStorage.getItem("code_verifier");
            if (!codeVerifier) {
                throw new Error("Code verifier not found. Please restart the authorization process.");
            }

            const response = await fetch("/api/teya/exchange-token", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ code, codeVerifier }),
            });

            const { accessToken, refreshToken, authTokenExpiry } = await response.json();
            await setTeyaTokens(accessToken, refreshToken, 900, 2592000);
            setAccessToken(accessToken);
            setRefreshToken(refreshToken);


            // Automatically fetch stores after obtaining the access token
            fetchStores(accessToken);
        } catch (err) {
            setError("Failed to exchange code for token. Please try again.");
            console.error(err);
        }
    };

    const fetchStores = async (token: string) => {
        setIsLoading(true);
        try {
            const response = await axios.get("/api/teya/stores", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const stores = response.data.stores || [];
            setStores(stores);
            // Automatically select store if there's only one
            if (stores.length === 1) {
                const singleStore = stores[0];
                setSelectedStore(singleStore.id); // Auto-select the single store
                fetchTerminals(singleStore.id, token, singleStore.name); // Fetch terminals for the single store
            }
        } catch (err) {
            setError("Failed to fetch stores. Please ensure your authorization is completed.");
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchTerminals = async (storeId: string, token: string, name:string) => {
        setIsLoading(true);
        try {
            const response = await axios.get("/api/teya/terminals", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    storeId,
                },
            });
            const terminals = response.data.terminals || [];
            setTerminals(terminals);

            // Automatically select terminal if there's only one
            if (terminals.length === 1) {
                const singleTerminal = terminals[0];
                setSelectedTerminal(singleTerminal.terminal_id);

                // Display confirmation message
                setPresetMessage(
                    `Found 1 terminal: ${singleTerminal.terminal_name} (Serial: ${singleTerminal.serial_number}) at store ${name}. This will be used as your card payment terminal. Press save to complete setup.`
                );
            }
        } catch (err) {
            setError("Failed to fetch terminals. Please try again.");
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    const saveTeyaSettings = async () => {

        await setTeyaTerminal(selectedStore, selectedTerminal);

        handleClose();
    };

    useEffect(() => {
        const checkExistingToken = async () => {
            const existingToken = await getAuth();
            if (existingToken != "") {
                setAccessToken(existingToken);
                setAuthCode("1");
                // Skip the auth step and directly fetch the stores
                fetchStores(existingToken);
            }
        };
        checkExistingToken();
    }, []);

    return (
        <>
            <Button onClick={handleOpen} colorScheme="teya">Teya Settings</Button>
            <Modal isOpen={isOpen} onClose={handleClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Teya Settings</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={4} align="stretch">
                            {error && (
                                <Alert status="error">
                                    <AlertIcon />
                                    {error}
                                </Alert>
                            )}

                            {!authCode && !accessToken && (
                                <Box>
                                    <Text mb={4}>
                                        To configure Teya, please authorize your account with Teya first.
                                    </Text>
                                    <Button colorScheme="teya" onClick={initiateAuthorization} leftIcon={<TeyaIcon style={{ width: "20px", height: "20px" }} />}>
                                        Authorize Teya
                                    </Button>
                                </Box>
                            )}

                            {/* Show preset message if available */}
                            {presetMessage && (
                                <Box>
                                    <Alert status="success">
                                        <AlertIcon />
                                        {presetMessage}
                                    </Alert>
                                </Box>
                            )}

                            {/* Allow manual setup if there are multiple options */}
                            {!presetMessage && stores.length > 0 && (
                                <>
                                    <FormControl>
                                        <Text>Select a Store</Text>
                                        <Select
                                            placeholder="Select Store"
                                            value={selectedStore}
                                            onChange={(e) => {
                                                const storeId = e.target.value;
                                                setSelectedStore(storeId);
                                                fetchTerminals(storeId, accessToken, "");
                                            }}
                                        >
                                            {stores.map((store) => (
                                                <option key={store.id} value={store.id}>
                                                    {store.name} - {store.address.city}
                                                </option>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    {terminals.length > 0 && (
                                        <FormControl>
                                            <Text>Select a Terminal</Text>
                                            <Select
                                                placeholder="Select Terminal"
                                                value={selectedTerminal}
                                                onChange={(e) => setSelectedTerminal(e.target.value)}
                                            >
                                                {terminals.map((terminal) => (
                                                    <option key={terminal.terminal_id} value={terminal.terminal_id}>
                                                        {terminal.terminal_name} (Serial: {terminal.serial_number})
                                                    </option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </>
                            )}

                            {isLoading && <Spinner />}
                        </VStack>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            onClick={saveTeyaSettings}
                            colorScheme="green"
                            isDisabled={!selectedTerminal}
                        >
                            Save Settings
                        </Button>
                        <Button onClick={handleClose} ml={3}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
