import React, { useState } from "react";
import {
  Flex,
  Spinner,
  Box,
} from "@chakra-ui/react";
import { startOfDay } from "date-fns";

import Card from "components/card/Card";
import CustomDateSelector from "../../../../components/reportDateSelector/index";
import useGetOrdersData from "../../../../api/reports/useGetOrdersData";
import { getRolloverDates } from "../../../../helpers/getRolloverDates";

import ReportConfigurator from "./components/ReportConfigurator";
import ReportTable from "./components/ReportTable";
import {useGetSalesData} from "../../../../api/reports/getSalesData";

export default function Configurable() {
  const dateTimeToday = getRolloverDates(1, startOfDay(new Date()));

  // date range state
  const [startDate, setStartDate] = useState(dateTimeToday[0]);
  const [endDate, setEndDate] = useState(dateTimeToday[1]);

  // (A) Customizable report config
  // You might store “selectedColumns” or “groupBy” in state:
  const [config, setConfig] = useState({
    selectedColumns: ["id", "date", "userName", "grossSales"], // default example
    groupBy: null,
    // Potentially store other options: sorting, additional filters, etc.
  });

  // (B) Using your custom hook to fetch data
  const [{ data: orders, isLoading, isError, refetch }] = useGetSalesData(startDate.toISOString(), endDate.toISOString())

  // (C) Handler to update date range + refetch
  // @ts-ignore
  const handleDateChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);

    refetch({
      query: {
        closedDate_gt: startDate.toISOString(),
        closedDate_lt: endDate.toISOString(),
      },
    });
  };

  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      {/* 1. Date Range Picker (already in your code) */}
      <CustomDateSelector handleDateChange={handleDateChange} />

      {/* 2. Card to hold the “configurator” + spinner + table */}
      <Card px='0px' mt={4}>
        {/* A) The “Report Configurator” to select columns, grouping, etc. */}
        <Box p={4}>
          <ReportConfigurator config={config} setConfig={setConfig} />
        </Box>

        {/* B) If loading, show spinner */}
        {isLoading && <Spinner />}

        {/* C) Once loaded, render your new table with dynamic columns */}
        {orders?.length > 0 && (
          <ReportTable orders={orders} config={config} />
        )}

        {/* Optional: If no orders found, show a friendly message */}
        {!isLoading && orders?.length === 0 && (
          <Box p={4}>No orders found for this date range.</Box>
        )}
      </Card>
    </Flex>
  );
}
