import { useRef, useState, useEffect, RefObject } from "react";
import "./segmentedStyle.css";

type Segment = {
    value: string;
    label: string;
    ref: RefObject<HTMLInputElement>;
};

type SegmentedControlProps = {
    name: string;
    segments: Segment[];
    callback: (value: string, index: number) => void;
    defaultIndex?: number;
    controlRef: RefObject<HTMLDivElement>;
    /** Optional controlled value. Use -1 to indicate no selection. */
    selectedSegment?: number;
    /** Optional callback to notify parent of a segment change */
    onSegmentChange?: (index: number) => void;
};

const SegmentedControl = ({
                              name,
                              segments,
                              callback,
                              defaultIndex = 0,
                              controlRef,
                              selectedSegment,
                              onSegmentChange,
                          }: SegmentedControlProps) => {
    const [activeIndex, setActiveIndex] = useState<number>(defaultIndex);

    // If selectedSegment is provided, update internal state accordingly.
    useEffect(() => {
        if (typeof selectedSegment === "number") {
            setActiveIndex(selectedSegment);
        }
    }, [selectedSegment]);

    useEffect(() => {
        if (
            activeIndex >= 0 &&
            segments[activeIndex] &&
            segments[activeIndex].ref.current &&
            controlRef.current
        ) {
            const { offsetWidth, offsetLeft } = segments[activeIndex].ref.current;
            const { style } = controlRef.current;
            style.setProperty("--highlight-width", `${offsetWidth}px`);
            style.setProperty("--highlight-x-pos", `${offsetLeft}px`);
        } else if (controlRef.current) {
            // Hide the highlight if no segment is selected.
            controlRef.current.style.setProperty("--highlight-width", `0px`);
        }
    }, [activeIndex, controlRef, segments]);

    useEffect(() => {
        const handleResize = () => {
            if (
                activeIndex >= 0 &&
                segments[activeIndex] &&
                segments[activeIndex].ref.current &&
                controlRef.current
            ) {
                const { offsetWidth, offsetLeft } = segments[activeIndex].ref.current;
                const { style } = controlRef.current;
                style.setProperty("--highlight-width", `${offsetWidth}px`);
                style.setProperty("--highlight-x-pos", `${offsetLeft}px`);
            }
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [activeIndex, controlRef, segments]);

    const onInputChange = (value: string, index: number) => {
        setActiveIndex(index);
        if (onSegmentChange) onSegmentChange(index);
        callback(value, index);
    };

    return (
        <div className="controls-container" ref={controlRef} >
            <div className={`controls ready`}>
                {segments?.map((item, i) => (
                    <div
                        key={item.value}
                        className={`segment ${i === activeIndex ? "active" : "inactive"}`}
                        ref={item.ref}
                    >
                        <input
                            type="radio"
                            value={item.value}
                            id={item.label}
                            name={name}
                            onChange={() => onInputChange(item.value, i)}
                            checked={i === activeIndex}
                        />
                        <label htmlFor={item.label}>{item.label}</label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SegmentedControl;
